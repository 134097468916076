import { takeLatest, call, put, select, all } from "@redux-saga/core/effects";
import CompanyService from "../../services/CompanyService";
import CompanyService2 from "../../services/CompanyService2";
import CompanyService3 from "../../services/CompanyService3";
import { STATUS_CODE } from "../../utils/Constants/systemSetting";
import {
  GET_ALL_COMPANIES_SAGA,
  GET_ALL_MARKET_CAP,
  GET_EXECUTIVES_SAGA,
  GET_MARKET_CAP_SAGA,
  GET_PARAGRAPHS_8K502_SAGA,
  SET_ALL_COMPANIES,
  SET_ALL_MARKET_CAP,
  SET_EXECUTIVES,
  SET_MARKET_CAP,
  SET_PARAGRAPHS_8K502,
  SET_MEDIAN_BY_YEAR,
  GET_MEDIAN_BY_YEAR_SAGA,
  DISCLOSURE_SEARCH,
  SET_DISCLOSURE_RESULTS,
  CLEAR_DISCLOSURE_RESULTS,
  COMP_AI_SEARCH,
  SET_COMP_AI_RESULTS,
  CLEAR_COMP_AI_RESULTS,
  SET_MONTHLY_RETURNS,
  GET_MONTHLY_RETURNS_SAGA,
  SET_MONTHLY_MARKETCAPS,
  GET_MONTHLY_MARKETCAPS_SAGA,
  SET_INDEX_MONTHLY_RETURNS,
  GET_BOARD_PAY_SAGA,
  SET_BOARD_PAY,
  GET_INDEX_MONTHLY_RETURNS_SAGA,
  SET_WEEKLY_RETURNS,
  GET_WEEKLY_RETURNS_SAGA,
  SET_DAILY_PRICES,
  GET_DAILY_PRICES_SAGA,
  COMP_AI_SEARCH_AVAILABILITY,
  SET_COMP_AI_AVAILABILITY_RESULTS,
  COMP_AI_RESULT_RATING,
  RUN_HORSE_RACE,
  SET_HORSE_RACE_RESULTS,
  CLEAR_HORSE_RACE_RESULTS,
  SET_OPTIMISTIC_HORSE_RACE_RESULTS,
  RUN_OPTIMISTIC_HORSE_RACE,
} from "../constants/CompanyConstant";
import {
  USER_INFO,
  ACCESS_TOKEN,
  KEY,
} from "../../utils/Constants/systemSetting";

import { parseJSON } from "../../utils/parseJSON.js";
import { ungzip } from "pako";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
// Static imports instead of backend requests for performance:
const companyDataRawPath = "/data/2021companyData.json.gz";
// const marketCapDataPath = "/data/2021marketCapData.json";
const csuiteDataRawPath = "/data/2021ExecutiveCompensationData.json.gz";

async function fetchLocal(path) {
  const response = await fetch(path);
  const result = await response.json().then((data) => {
    // console.log(Buffer.from(data.data));
    data = parseJSON(Buffer.from(data.data)); // If encrypted
    // data = ungzip(data, { to: "string" }); //If gzipped
    // data = JSON.parse(data);
    return data;
  });
  // console.log(path, result);
  return result;
}

async function parseData(data) {
  const parsedData = parseJSON(Buffer.from(data.data));
  return parsedData;
}

const handleSignOut = async () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(USER_INFO);
  localStorage.removeItem(KEY);
  localStorage.setItem(KEY, "expired");
};

function* getAllCompanies() {
  try {
    const { data, status } = yield call(() => CompanyService.getAllCompanies());
    if (status === STATUS_CODE.SUCCESS) {
      // // old method using local (public) files:
      // const companyDataRaw = yield call(() => fetchLocal(companyDataRawPath)); // old method using local (public) files
      // const data = yield call(() => companyDataRaw); //parseJSON(Buffer.from(companyDataRaw.data)));
      // const data = yield call(() => data); //parseJSON(Buffer.from(companyDataRaw.data)));
      const parsedData = yield call(() => parseData(data)); //parseJSON(Buffer.from(companyDataRaw.data)));
      if (parsedData === "key_not_valid") {
        handleSignOut();
        toast.error(
          "Session has expired. Please sign out and log in to your account again."
        );
        window.location.href = "/login";
        return;
      }
      // const result = [];
      // for (let item of Object.values(parsedData)) {
      //   result.push(item);
      // }
      // console.log(result);
      try {
        const { data, status } = yield call(() =>
          CompanyService.getAllMarketCap()
        );
        if (status === STATUS_CODE.SUCCESS) {
          for (const company of data) {
            if (parsedData?.[company.Ticker]) {
              parsedData[company.Ticker]["MarketCap"] = company.marketCap;
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
      // console.log(parsedData);
      yield put({
        type: SET_ALL_COMPANIES,
        companies: parsedData,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

function* getAllExecutives() {
  try {
    // Old method:
    // const csuiteDataRaw = yield call(() => fetchLocal(csuiteDataRawPath));
    // const result = yield call(() => csuiteDataRaw); //parseJSON(Buffer.from(csuiteDataRaw.data)));
    //// New method:
    const { data, status } = yield call(() =>
      CompanyService.getAllExecutives()
    );
    if (status === STATUS_CODE.SUCCESS) {
      const parsedData = yield call(() => parseData(data)); //parseJSON(Buffer.from(companyDataRaw.data)));
      // console.log(parsedData);
      if (parsedData === "key_not_valid") {
        handleSignOut();
        window.location.href = "/login";
        return;
      }
      yield put({
        type: SET_EXECUTIVES,
        csuite: parsedData,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

function* getAllIndexes() {
  try {
    const dowTickers = yield call(() => fetchLocal("/Data/DowTickers.json"));
    const RussellTickers = yield call(() =>
      fetchLocal("/Data/Russell3000Tickers.json")
    );
    const SP500Tickers = yield call(() =>
      fetchLocal("/Data/SP500Tickers.json")
    );
    const result = { dowTickers, RussellTickers, SP500Tickers };

    // yield put({
    //   type: SET_ALL_INDEXES, // Define next
    //   companies: result,
    // });
  } catch (err) {
    console.log(err);
  }
}

function* getAllMarketCap() {
  try {
    const { data, status } = yield call(() => CompanyService.getAllMarketCap());
    if (status === STATUS_CODE.SUCCESS) {
      const sortedMarketCap = data
        // .filter((mCap) => filteredCompanies.includes(mCap?.Ticker))
        .sort(function (a, b) {
          if (a.marketCap === "NaN" || a.marketCap === "0") a.marketCap = null;
          if (b.marketCap === "NaN" || b.marketCap === "0") b.marketCap = null;
          return b.marketCap - a.marketCap;
        });
      yield put({
        type: SET_ALL_MARKET_CAP,
        marketCap: sortedMarketCap,
      });
    }
  } catch (err) {
    console.log(err.response);
  }
}

// function * getAllExecutives() {
//     try {
//         const {status, data} = yield call(() => CompanyService2.getExecutives());
//         if (status === STATUS_CODE.SUCCESS) {
//             yield put({
//                 type: SET_EXECUTIVES,
//                 executives: data
//             })
//         }
//     } catch(err) {
//         console.log(err.response)
//     }
// }

function* getMarketCap({ ticker }) {
  try {
    const { data, status } = yield call(() =>
      CompanyService2.getMarketCap(ticker)
    );
    if (status === STATUS_CODE.SUCCESS) {
      yield put({
        type: SET_MARKET_CAP,
        marketCap: data,
      });
    }
  } catch (err) {
    console.log(err.response);
  }
}

function* getParagraphsFrom8k502({ ticker }) {
  try {
    const { data, status } = yield call(() =>
      CompanyService2.getParagraphsFrom8k502(ticker)
    );
    if (status === STATUS_CODE.SUCCESS) {
      yield put({
        type: SET_PARAGRAPHS_8K502,
        paragraphsFrom8k502: data,
      });
    }
  } catch (err) {
    console.log(err.response);
    yield put({
      type: SET_PARAGRAPHS_8K502,
      paragraphsFrom8k502: {},
    });
  }
}

function* getMonthlyReturns({ tickers, token }) {
  try {
    const { data, status } = yield call(() =>
      CompanyService2.getMonthlyReturns(tickers, token)
    );
    if (status === STATUS_CODE.SUCCESS) {
      yield put({
        type: SET_MONTHLY_RETURNS,
        monthlyReturns: data,
      });
    }
  } catch (err) {
    console.log(err.response);
    yield put({
      type: SET_MONTHLY_RETURNS,
      monthlyReturns: {},
    });
  }
}

function* getWeeklyReturns({ tickers, token }) {
  try {
    const { data, status } = yield call(() =>
      CompanyService2.getWeeklyReturns(tickers, token)
    );
    if (status === STATUS_CODE.SUCCESS) {
      yield put({
        type: SET_WEEKLY_RETURNS,
        weeklyReturns: data,
      });
    }
  } catch (err) {
    console.log(err.response);
    yield put({
      type: SET_WEEKLY_RETURNS,
      weeklyReturns: {},
    });
  }
}

function* getDailyPrices({ tickers, token }) {
  try {
    const { data, status } = yield call(() =>
      CompanyService2.getDailyPrices(tickers, token)
    );
    if (status === STATUS_CODE.SUCCESS) {
      yield put({
        type: SET_DAILY_PRICES,
        dailyPrices: data,
      });
    }
  } catch (err) {
    console.log(err.response);
    yield put({
      type: SET_DAILY_PRICES,
      dailyPrices: {},
    });
  }
}

function* getMonthlyMarketcaps({ tickers, token }) {
  try {
    const { data, status } = yield call(() =>
      CompanyService2.getMonthlyMarketcaps(tickers, token)
    );
    if (status === STATUS_CODE.SUCCESS) {
      yield put({
        type: SET_MONTHLY_MARKETCAPS,
        monthlyMarketcaps: data,
      });
    }
  } catch (err) {
    console.log(err.response);
    yield put({
      type: SET_MONTHLY_MARKETCAPS,
      monthlyMarketcaps: {},
    });
  }
}

function* getIndexMonthlyReturns({ token }) {
  try {
    const { data, status } = yield call(() =>
      CompanyService2.getIndexMonthlyReturns(token)
    );
    if (status === STATUS_CODE.SUCCESS) {
      yield put({
        type: SET_INDEX_MONTHLY_RETURNS,
        indexMonthlyReturns: data,
      });
    }
  } catch (err) {
    console.log(err.response);
    yield put({
      type: SET_INDEX_MONTHLY_RETURNS,
      indexMonthlyReturns: {},
    });
  }
}

function* getBoardPay({ ticker, token }) {
  try {
    const { data, status } = yield call(() =>
      CompanyService2.getBoardPay(ticker, token)
    );
    if (status === STATUS_CODE.SUCCESS) {
      yield put({
        type: SET_BOARD_PAY,
        boardPay: data,
      });
    }
  } catch (err) {
    console.log(err.response);
    // if (err?.response?.data) toast.error(err.response.data);
    yield put({
      type: SET_BOARD_PAY,
      boardPay: {},
    });
  }
}

function* getMedianByYear({ year }) {
  try {
    const { data, status } = yield call(() =>
      CompanyService2.getMedianByYear(year)
    );
    if (status === STATUS_CODE.SUCCESS) {
      yield put({
        type: SET_MEDIAN_BY_YEAR,
        medianByYear: data,
      });
    }
  } catch (err) {
    console.log(err.response);
    yield put({
      type: SET_MEDIAN_BY_YEAR,
      medianByYear: {},
    });
  }
}

function* disclosureSearch({ search }) {
  const { cik, searchQuery, token } = search;
  try {
    const { data, error, status } = yield call(() =>
      CompanyService3.disclosureSearch(cik, searchQuery, token)
    );
    // Most error handling is handled by NEXT.js backend, return 200 to prevent NGINX from intercepting error message:
    if (status === STATUS_CODE.SUCCESS) {
      yield put({
        type: SET_DISCLOSURE_RESULTS,
        disclosureResults: data,
        error: error ? error : null,
      });
    }
  } catch (err) {
    console.log(err.response);
    yield put({
      type: SET_DISCLOSURE_RESULTS,
      disclosureResults: {
        disclosureResults: [],
        error: "Something went wrong. Please try again later.",
      },
    });
  }
}

function* clearDisclosureResults() {
  yield put({
    type: SET_DISCLOSURE_RESULTS,
    disclosureResults: [],
  });
}

function* compAISearch({ search }) {
  const { ticker, searchQuery, token, id } = search;
  // console.log(ticker, searchQuery, token);
  try {
    const { data, error, status } = yield call(() =>
      CompanyService2.compAISearch(search, token)
    );
    // Most error handling is handled by NEXT.js backend, return 200 to prevent NGINX from intercepting error message:
    if (status === STATUS_CODE.SUCCESS) {
      yield put({
        type: SET_COMP_AI_RESULTS,
        compAIResults: data,
        id: id,
        error: error ? error : null,
      });
    }
  } catch (err) {
    console.log(err);
    yield put({
      type: SET_COMP_AI_RESULTS,
      compAIResults: {
        compAIResults: [],
        error: "Something went wrong. Please try again later.",
      },
    });
  }
}

function* clearCompAIResults() {
  yield put({
    type: SET_COMP_AI_RESULTS,
    compAIResults: [],
  });
}

function* compAIAvailability({ search }) {
  const { ticker, token } = search;
  // console.log(ticker, searchQuery, token);
  try {
    const { data, error, status } = yield call(() =>
      CompanyService2.compAIAvailability(ticker, token)
    );
    // Most error handling is handled by NEXT.js backend, return 200 to prevent NGINX from intercepting error message:
    if (status === STATUS_CODE.SUCCESS) {
      yield put({
        type: SET_COMP_AI_AVAILABILITY_RESULTS,
        compAIAvailability: data,
        error: error ? error : null,
      });
    }
  } catch (err) {
    console.log(err);
    yield put({
      type: SET_COMP_AI_AVAILABILITY_RESULTS,
      compAIAvailability: {
        compAIAvailability: [],
        error: "Something went wrong. Please try again later.",
      },
    });
  }
}

function* compAIResultRating({ ratingDetails }) {
  const { token, messageID, rating } = ratingDetails;
  // console.log(ticker, searchQuery, token);
  try {
    const { data, status } = yield call(() =>
      CompanyService2.compAIResultRating(ratingDetails, token)
    );
    if (status === STATUS_CODE.SUCCESS) {
    }
  } catch (err) {
    console.log(err);
  }
}

function* runHorseRace({ search }) {
  const { searchParams, token } = search;

  try {
    const { data, error, status } = yield call(() =>
      CompanyService3.runHorseRace(searchParams, token)
    );

    // Most error handling is handled by NEXT.js backend, return 200 to prevent NGINX from intercepting error message:
    if (status === STATUS_CODE.SUCCESS) {
      yield put({
        type: SET_HORSE_RACE_RESULTS,
        horseRaceResults: data,
        error: error ? error : null,
      });
    }
  } catch (err) {
    console.log(err);
    yield put({
      type: SET_HORSE_RACE_RESULTS,
      horseRaceResults: {
        horseRaceResults: [],
        optimisticHorseRaceResults: [],
        error: "Something went wrong. Please try again later.",
      },
    });
  }
}

function* runOptimisticHorseRace({ search }) {
  const { searchParams, token } = search;

  try {
    const { data, error, status } = yield call(() =>
      CompanyService3.runHorseRace(searchParams, token)
    );

    // Most error handling is handled by NEXT.js backend, return 200 to prevent NGINX from intercepting error message:
    if (status === STATUS_CODE.SUCCESS) {
      yield put({
        type: SET_OPTIMISTIC_HORSE_RACE_RESULTS,
        optimisticHorseRaceResults: data,
        error: error ? error : null,
      });
    }
  } catch (err) {
    console.log(err);
    yield put({
      type: SET_OPTIMISTIC_HORSE_RACE_RESULTS,
      optimisticHorseRaceResults: {
        optimisticHorseRaceResults: [],
        error: "Something went wrong. Please try again later.",
      },
    });
  }
}

function* clearHorseRaceResults() {
  yield put({
    type: SET_HORSE_RACE_RESULTS,
    horseRaceResults: [],
  });
  yield put({
    type: SET_OPTIMISTIC_HORSE_RACE_RESULTS,
    optimisticHorseRaceResults: [],
  });
}

export function* watchingGetAllCompanies() {
  yield takeLatest(GET_ALL_COMPANIES_SAGA, getAllCompanies);
}

export function* watchingGetAllMarketCap() {
  yield takeLatest(GET_ALL_MARKET_CAP, getAllMarketCap);
}

export function* watchingGetAllExecutives() {
  yield takeLatest(GET_EXECUTIVES_SAGA, getAllExecutives);
}

export function* watchingGetMarketCap() {
  yield takeLatest(GET_MARKET_CAP_SAGA, getMarketCap);
}

export function* watchingGetParagraphsFrom8k502() {
  yield takeLatest(GET_PARAGRAPHS_8K502_SAGA, getParagraphsFrom8k502);
}

export function* watchingGetMonthlyReturns() {
  yield takeLatest(GET_MONTHLY_RETURNS_SAGA, getMonthlyReturns);
}

export function* watchingGetWeeklyReturns() {
  yield takeLatest(GET_WEEKLY_RETURNS_SAGA, getWeeklyReturns);
}

export function* watchingGetDailyPrices() {
  yield takeLatest(GET_DAILY_PRICES_SAGA, getDailyPrices);
}

export function* watchingGetIndexMonthlyRetruns() {
  yield takeLatest(GET_INDEX_MONTHLY_RETURNS_SAGA, getIndexMonthlyReturns);
}

export function* watchingGetMonthlyMarketcaps() {
  yield takeLatest(GET_MONTHLY_MARKETCAPS_SAGA, getMonthlyMarketcaps);
}

export function* watchingGetBoardPay() {
  yield takeLatest(GET_BOARD_PAY_SAGA, getBoardPay);
}

export function* watchingGetMedianByYear() {
  yield takeLatest(GET_MEDIAN_BY_YEAR_SAGA, getMedianByYear);
}
export function* watchingDisclosureSearch() {
  yield takeLatest(DISCLOSURE_SEARCH, disclosureSearch);
}

export function* watchingClearDisclosureResults() {
  yield takeLatest(CLEAR_DISCLOSURE_RESULTS, clearDisclosureResults);
}

export function* watchingCompAISearch() {
  yield takeLatest(COMP_AI_SEARCH, compAISearch);
}

export function* watchingCompAIAvailability() {
  yield takeLatest(COMP_AI_SEARCH_AVAILABILITY, compAIAvailability);
}

export function* watchingClearCompAIResults() {
  yield takeLatest(CLEAR_COMP_AI_RESULTS, clearCompAIResults);
}

export function* watchingCompAIResultRating() {
  yield takeLatest(COMP_AI_RESULT_RATING, compAIResultRating);
}

export function* watchingRunHorseRace() {
  yield takeLatest(RUN_HORSE_RACE, runHorseRace);
}

export function* watchingRunOptimisticHorseRace() {
  yield takeLatest(RUN_OPTIMISTIC_HORSE_RACE, runOptimisticHorseRace);
}

export function* watchingClearHorseRaceResults() {
  yield takeLatest(CLEAR_HORSE_RACE_RESULTS, clearHorseRaceResults);
}
