import React, { useState, useEffect, useRef } from "react";
import { Input, Tooltip } from "antd";
import { formatMeasure, emdash } from "../../../utils/Constants/systemSetting";

export default function EditableTableCellv2({
  cellData, // The current cell's data, can be affected by state
  dataType, // string or other type found in formatMeasure
  updateItem = null, // Item (state object) to be updated
  updateIndex = 0, //Object index (row) needing update; updateItem[updateIndex]
  updateKey = null, //Object key (cell) needing update; updateItem[updateIndex][updateKey]
  setUpdateItem = () => null, // SetState for the updateItem
  originalValue = null, // Orignal value unaffected by state
  setUpdateCount = () => null, //setState used for keeping track of updates made to table
  displayZero = false,
}) {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(cellData);
  const inputRef = useRef(null);

  const formatValue = (val) => {
    if (!val || val === emdash || val === "N/A" || val === "0" || val === "-")
      return displayZero && (val === 0 || val === "0") ? 0 : emdash;
    if (dataType === "dollar") {
      return formatMeasure(
        parseFloat(val?.toString()?.replaceAll(",", "")),
        "dollar"
      )
        ?.toString()
        ?.replace("$", "");
    } else if (dataType === "string") {
      return val.toString();
    } else if (dataType) {
      return formatMeasure(
        parseFloat(val?.toString()?.replaceAll(",", "")),
        dataType
      );
    }
    return val;
  };

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const filterInput = (input) => {
    let newInput = input;
    if (dataType !== "string") {
      newInput = newInput.replace(/[^0-9.]/g, "");
      newInput = newInput.slice(0, 15);
    }
    newInput = newInput.slice(0, 25);
    if (newInput.split(".").length > 2) {
      newInput = newInput.split(".").slice(0, 2).join(".");
    }
    return newInput;
  };

  function updateObjectValue(object, index, valueKey, value) {
    const isUndefined = (item) => typeof item === undefined;
    if (
      isUndefined(object) ||
      isUndefined(index) ||
      isUndefined(valueKey) ||
      isUndefined(value)
    ) {
      return null;
    }
    const result = { ...object };
    result[index][valueKey] = value;
    return result[index];
  }

  const valuesChanged =
    (value || value === "") &&
    value.toString().replaceAll(",", "").replaceAll("%", "") !==
      cellData.toString().replaceAll(",", "").replaceAll("%", "");

  const getNumericalValue = (val) => {
    if (!val || isNaN(parseFloat(val))) return null;
    return parseFloat(val.toString().replaceAll(",", "").replaceAll("%", ""));
  };

  const numericalValue = getNumericalValue(value);

  if (!editing && value && valuesChanged && updateItem) {
    const newItem = updateObjectValue(
      updateItem,
      updateIndex,
      updateKey,
      dataType === "string" ? value : numericalValue
    );
    if (newItem) {
      setUpdateItem((prev) => {
        if (prev.some((item) => item.key === newItem.key)) {
          prev[prev.indexOf(prev.find((item) => item.key === newItem.key))] =
            newItem;
          return prev;
        } else {
          return [...prev, newItem];
        }
      });
      setUpdateCount((prev) => (prev += 1));
    }
  }

  return (
    <div>
      {!editing && (
        <>
          <Tooltip title={"Click to edit this value"}>
            <div
              onClick={() => {
                setEditing(true);
              }}
              style={{ cursor: "pointer" }}
            >
              {formatValue(value || cellData)}
            </div>
          </Tooltip>
        </>
      )}

      <div style={{ display: editing ? "block" : "none" }}>
        <Input
          onChange={(e) => setValue(filterInput(e.target.value, dataType))}
          onBlur={() => {
            setEditing(false);
          }}
          value={value || originalValue}
          ref={inputRef}
          onPressEnter={() => setEditing(false)}
          style={{ width: 200, margin: 0, textAlign: "center" }}
        />
      </div>
    </div>
  );
}
