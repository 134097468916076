import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  Tooltip,
  Label,
  ResponsiveContainer,
} from "recharts";
import { Table, Select, Checkbox, Switch, Tooltip as AntdTooltip } from "antd";
import { getWeeklyReturns } from "../../redux/actions/CompanyAction";

import { RadioSelector } from "../../utils/Constants/systemSetting";
import FinalValue from "./utils/FinalValue";

import { OptionsFAQTopics } from "../../assets/faqTopicsOptions";
import { hasHoverText } from "../../utils/Constants/systemSetting";

const lookBackIntervalsOptions = [
  { label: "6 Months", value: "6mo" },
  { label: "1 Year", value: "1yr" },
  { label: "2 Years", value: "2yr" },
  { label: "3 Years", value: "3yr" },
  { label: "5 Years", value: "5yr" },
  { label: "10 Years", value: "10yr" },
];

export default function VolatilityCalculator({
  companyInfo,
  showVolatilityEditView,
  lookbackVolatilityBlendedAverage,
  setLookbackVolatilityBlendedAverage,
  calculatingVolatility,
  setCalculatingVolatility,
}) {
  const dispatch = useDispatch();
  const { weeklyReturns } = useSelector((state) => state.CompanyReducer);
  useEffect(() => {
    const token = localStorage.getItem("ACCESS_TOKEN");
    if (companyInfo?.Ticker) {
      dispatch(getWeeklyReturns([companyInfo.Ticker], token));
    }
  }, [companyInfo, dispatch]);

  const decimalPlaces = 2; // For ".toFixed(X)"

  useEffect(() => {
    if (
      !weeklyReturns ||
      !Object.keys(weeklyReturns).some((item) => item === companyInfo.Ticker)
    ) {
      setCalculatingVolatility(true);
    } else {
      setCalculatingVolatility(false);
    }
  }, [weeklyReturns, companyInfo, setCalculatingVolatility]);

  const [trailingVolatilityWindow, setTrailingVolatilityWindow] =
    useState("1yr");

  const trailingVolatilityWindowWeeks = useMemo(() => {
    return trailingVolatilityWindow.split("yr")[0] * 52;
  }, [trailingVolatilityWindow]);

  const [customEndDate, setCustomEndDate] = useState(null);

  const [lookbackIntervalsSelected, setLookbackIntervalsSelected] = useState([
    "6mo",
    "1yr",
    "2yr",
    "3yr",
  ]);

  const dataExists = (data) => {
    return (
      data !== "#N/A" && !isNaN(parseFloat(data)) && parseFloat(data) >= -1
    );
  };

  // useEffect(() => {
  //   setLookbackIntervalsSelected(["6mo", "1yr", "2yr", "3yr"]);
  // }, []);

  const formatDateString = (date) =>
    new Date(date + "T00:00:00").toLocaleDateString();

  const allWeeklyReturnData = useMemo(() => {
    const weeklyReturnsForCompany = weeklyReturns?.[companyInfo.Ticker];
    if (!weeklyReturnsForCompany) return [];

    const formattedAllWeeklyReturns = Object.entries(weeklyReturnsForCompany)
      .filter(([key, val]) => key.includes("000Z"))
      .map(([key, val], index) => ({
        date: key.split("T0")[0],
        "Weekly Return": val,
        key: index,
      }))
      .sort((a, b) => new Date(a.date) - new Date(b.date));

    return formattedAllWeeklyReturns;
  }, [companyInfo, weeklyReturns]);

  const weeklyReturnData = useMemo(() => {
    if (
      !allWeeklyReturnData.indexOf(
        allWeeklyReturnData.find((item) => item["date"] === customEndDate)
      ) ||
      !allWeeklyReturnData?.[
        allWeeklyReturnData.indexOf(
          allWeeklyReturnData.find((item) => item["date"] === customEndDate)
        ) + 1
      ]
    ) {
      return allWeeklyReturnData;
    }
    return allWeeklyReturnData.slice(
      0,
      customEndDate
        ? allWeeklyReturnData.indexOf(
            allWeeklyReturnData.find((item) => item["date"] === customEndDate)
          ) + 1
        : undefined
    );
  }, [allWeeklyReturnData, customEndDate]);

  const weeklyReturnWeeksAvailable = useMemo(() => {
    return allWeeklyReturnData
      .map((item) => item["date"])
      .slice(trailingVolatilityWindowWeeks + 1);
  }, [allWeeklyReturnData, trailingVolatilityWindowWeeks]);

  const defaultEndDate = useMemo(() => {
    if (!weeklyReturnWeeksAvailable.length) return null;
    return weeklyReturnWeeksAvailable[weeklyReturnWeeksAvailable.length - 1];
  }, [weeklyReturnWeeksAvailable]);

  useEffect(() => {
    if (!customEndDate && defaultEndDate) {
      setCustomEndDate(defaultEndDate);
    }
  }, [customEndDate, defaultEndDate]);

  const getStandardDeviation = (array) => {
    if (!array.length) return;
    const n = array.length;
    const mean = array.reduce((a, b) => a + b) / n;
    return Math.sqrt(
      array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / (n - 1)
    );
  };

  const weeklyVolatilityData = useMemo(() => {
    if (!weeklyReturnData) return [];

    const sortedWeeklyReturnData = [...weeklyReturnData].reverse();
    const weeklyVolatilityData = [];

    for (const weeklyReturn of sortedWeeklyReturnData) {
      const futureValueExists = sortedWeeklyReturnData
        .slice(sortedWeeklyReturnData.indexOf(weeklyReturn))
        .some((d) => dataExists(d["Weekly Return"]));
      if (!futureValueExists) {
        return weeklyVolatilityData;
      }

      const indexOfWeeklyReturn = sortedWeeklyReturnData.indexOf(weeklyReturn);
      if (
        !(
          sortedWeeklyReturnData?.[
            indexOfWeeklyReturn + trailingVolatilityWindowWeeks
          ]?.["Weekly Return"] >= -1
        )
      ) {
        weeklyVolatilityData.push({
          Date: weeklyReturn.date,
          "Weekly Return": weeklyReturn["Weekly Return"],
          "Trailing Weekly Volatility": "",
        });
        continue;
      }
      const weeklyReturnsForPeriodSelectedWithoutDates = sortedWeeklyReturnData
        .slice(
          indexOfWeeklyReturn,
          indexOfWeeklyReturn + trailingVolatilityWindowWeeks
        )
        .map((item) => item?.["Weekly Return"]);
      const standardDeviationForRemainingWeeks = getStandardDeviation(
        weeklyReturnsForPeriodSelectedWithoutDates
      );
      const weeklyVolatility =
        standardDeviationForRemainingWeeks * Math.sqrt(52);

      weeklyVolatilityData.push({
        Date: weeklyReturn.date,
        "Weekly Return": weeklyReturn["Weekly Return"],
        "Trailing Weekly Volatility": !isNaN(parseFloat(weeklyVolatility))
          ? weeklyVolatility * 100
          : "",
      });
    }
    return weeklyVolatilityData;
  }, [weeklyReturnData, trailingVolatilityWindowWeeks]);

  const [updateCount, setUpdateCount] = useState(1);

  const handleLookbackIntervalChange = (lookbackInterval) => {
    if (lookbackIntervalsSelected.includes(lookbackInterval)) {
      // Never go to 0 lookback intervals. Otherwise calulation fails
      if (lookbackIntervalsSelected.length === 1) return;
      setLookbackIntervalsSelected([
        ...lookbackIntervalsSelected.filter(
          (item) => item !== lookbackInterval
        ),
      ]);
    } else {
      lookbackIntervalsSelected.push(lookbackInterval);
      setLookbackIntervalsSelected([...lookbackIntervalsSelected]);
    }
    setUpdateCount((prev) => prev + 1);
    return;
  };

  const volatilityLookbackTableColumns = [
    {
      title: "Use in Blended Average?",
      dataIndex: "Use in Blended Average?",
      key: "Use in Blended Average?",
      render: (text, record) => {
        return (
          <Checkbox
            checked={lookbackIntervalsSelected.includes(
              record["Lookback Interval"]
            )}
            onChange={() => {
              handleLookbackIntervalChange(record["Lookback Interval"]);
            }}
            key={updateCount}
          />
        );
      },
      align: "center",
      width: 200,
    },
    {
      title: "Lookback Interval",
      dataIndex: "Lookback Interval",
      key: "Lookback Interval",
      render: (text) => (
        <p>
          {text
            .replace("mo", " Months")
            .replace("yr", " Year" + (text.split("yr")[0] === "1" ? "" : "s"))}
        </p>
      ),
      align: "center",
    },
    {
      title: "Lookback Volatility",
      dataIndex: "Lookback Volatility",
      key: "Lookback Volatility",
      align: "center",
      render: (text) => (
        <p>{isNaN(text) ? "N/A" : text.toFixed(decimalPlaces) + "%"}</p>
      ),
    },
  ];

  const volatilityLookbackData = useMemo(() => {
    const lookbackIntervalWeeks = lookBackIntervalsOptions.map(
      (intervalLabelAndValue) => {
        const interval = intervalLabelAndValue.value;
        return interval.includes("mo")
          ? interval.split("mo")[0] * (52 / 12)
          : interval.split("yr")[0] * 52;
      }
    );
    const volatilityLookbackData = lookBackIntervalsOptions.map(
      (intervalLabelAndValue, index) => {
        const interval = intervalLabelAndValue.value;
        const volatilityLookBack =
          getStandardDeviation(
            [...weeklyVolatilityData]
              .slice(0, lookbackIntervalWeeks[index])
              .map((item) => item?.["Weekly Return"])
          ) * Math.sqrt(52); //TODO: 52 or lookbackIntervalWeeks?
        return {
          "Use in Blended Average?":
            lookbackIntervalsSelected.includes(interval),
          "Lookback Interval": interval,
          "Lookback Volatility": volatilityLookBack * 100,
          key: interval,
        };
      }
    );
    return volatilityLookbackData;
  }, [weeklyVolatilityData, lookbackIntervalsSelected]);

  useEffect(() => {
    const getAverage = (array) =>
      array.reduce((a, b) => a + b, 0) / array.length;
    const numericalVolatilitiesAvailable = volatilityLookbackData
      .filter((item) =>
        lookbackIntervalsSelected.includes(item["Lookback Interval"])
      )
      .map((item) => item["Lookback Volatility"])
      .filter((item) => !isNaN(parseFloat(item)));
    const lookBackBlendedAverage = getAverage(numericalVolatilitiesAvailable);
    setLookbackVolatilityBlendedAverage(lookBackBlendedAverage);
    return;
  }, [
    lookbackIntervalsSelected,
    volatilityLookbackData,
    setLookbackVolatilityBlendedAverage,
  ]);

  const abbreviateDateString = (formattedDateString) => {
    const baseDate = formattedDateString.split(/\/20..$/)[0];
    const year = formattedDateString.split(/.*\/.*\//)[1];
    const abbreviatedYear = year.substring(2);
    // Return initial input in case of error
    if (!baseDate || abbreviatedYear.length !== 2) {
      return formattedDateString;
    }
    return `${baseDate}/${abbreviatedYear}`;
  };

  const CustomTooltip = ({ active, payload, label, dataLabel }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "rgb(255,255,255,0.85)",
            padding: 5,
            outline: "0.5px solid gray",
          }}
        >
          <p className="label">{`Date: ${formatDateString(label)}`}</p>
          <p className="label">{`${dataLabel}: ${
            !isNaN(parseFloat(payload[0].value))
              ? parseFloat(payload[0].value).toFixed(decimalPlaces) + "%"
              : payload[0].value
          }`}</p>
        </div>
      );
    }
    return <div></div>;
  };

  const adjustedWeeklyReturnData = useMemo(() => {
    if (!weeklyReturnData) return [];

    const adjustedWeeklyReturnData = [];
    let firstValueFound = false;
    for (const weeklyReturn of weeklyReturnData) {
      if (!firstValueFound && !dataExists(weeklyReturn["Weekly Return"])) {
        continue;
      } else {
        adjustedWeeklyReturnData.push(weeklyReturn);
        if (!firstValueFound) firstValueFound = true;
      }
    }
    return adjustedWeeklyReturnData;
  }, [weeklyReturnData]);

  return (
    <>
      {/* <div className="company-calculator-section col-lg-9 executives p-0"> */}
      {showVolatilityEditView && (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h1>Volatility Calculator:</h1>
          </div>
          <div style={{ justifyContent: "flex-start" }}>
            <div className="required-for-responsive-chart"></div>
            <AntdTooltip
              title={OptionsFAQTopics.weekly_returns_for_volatility.short_desc}
              overlayClassName="white-link"
              overlayInnerStyle={{ width: 500 }}
            >
              <div style={{ display: "inline-block" }}>
                {hasHoverText(
                  <h2
                    style={{
                      fontSize: "2.25rem",
                      marginRight: 10,
                      marginBottom: 10,
                    }}
                  >
                    Weekly Returns for {companyInfo.Company}
                  </h2>
                )}
              </div>
            </AntdTooltip>
            <ResponsiveContainer width="100%" height="87.5%">
              <LineChart
                width={500}
                height={500}
                data={adjustedWeeklyReturnData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  tickFormatter={(tick) =>
                    abbreviateDateString(formatDateString(tick))
                  }
                />
                <YAxis />
                <Tooltip
                  content={<CustomTooltip dataLabel="Weekly Return" />}
                />
                <Line
                  type="monotone"
                  dataKey="Weekly Return"
                  stroke="var(--secondary)"
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div style={{ width: "100%", height: 500 }}>
            <span>
              <h2>
                Trailing Weekly Volatility for {companyInfo.Company} (n ={" "}
                {trailingVolatilityWindowWeeks})
              </h2>
            </span>
            <div style={{ marginLeft: 20, marginBottom: 20 }}>
              <RadioSelector
                options={["1yr", "2yr", "3yr"]}
                state={trailingVolatilityWindow}
                setState={setTrailingVolatilityWindow}
                heading="Trailing Volatility Window:"
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 10,
                }}
              >
                <h3 style={{ fontSize: 20, marginRight: 10, marginBottom: 0 }}>
                  {"Effective End Date:".split(" ").map((head, index) => (
                    <span key={index}>{head}&nbsp;</span>
                  ))}
                </h3>
                <Select
                  style={{ width: 120, textAlign: "center" }}
                  onChange={(value) => {
                    setCustomEndDate(value);
                  }}
                  value={customEndDate}
                  options={[...weeklyReturnWeeksAvailable]
                    .reverse()
                    .map((item, key) => ({
                      value: item,
                      label: formatDateString(item),
                      key,
                    }))}
                  dropdownStyle={{ textAlign: "center" }}
                />
              </div>
            </div>
            <ResponsiveContainer width="100%" height="95%">
              <LineChart
                width={500}
                height={300}
                data={[
                  ...weeklyVolatilityData.filter(
                    (item) => item?.["Trailing Weekly Volatility"]
                  ),
                ].reverse()}
                margin={{
                  top: 5,
                  right: 100,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="Date"
                  tickFormatter={(tick) =>
                    abbreviateDateString(formatDateString(tick))
                  }
                />
                <YAxis
                  tickFormatter={(tick) => {
                    return `${parseFloat(tick).toFixed(1)}%`;
                  }}
                  domain={["dataMin - 1", "dataMax + 1"]}
                />
                <Tooltip
                  content={
                    <CustomTooltip dataLabel="Trailing Weekly Volatility" />
                  }
                />
                <Line
                  type="monotone"
                  dataKey="Trailing Weekly Volatility"
                  stroke="var(--secondary)"
                  dot={false}
                />
                <ReferenceLine
                  y={lookbackVolatilityBlendedAverage}
                  stroke="var(--red)"
                  strokeWidth={3.5}
                  strokeDasharray="9 3"
                  label={
                    <Label
                      value={`${lookbackVolatilityBlendedAverage?.toFixed(
                        decimalPlaces
                      )}%`}
                      offset={-50}
                      position="insideRight"
                      fontSize={15}
                      style={{ fontWeight: "bold" }}
                    />
                  }
                  ifOverflow={"extendDomain"}
                  isFront={true}
                />
                {volatilityLookbackData
                  .filter((item) =>
                    lookbackIntervalsSelected.includes(
                      item["Lookback Interval"]
                    )
                  )
                  .map((item) => {
                    return (
                      <ReferenceLine
                        y={item?.["Lookback Volatility"]}
                        stroke="var(--red)"
                        strokeWidth={1}
                        strokeDasharray="6 6"
                        label={
                          <Label
                            // value={`${item?.["Lookback Volatility"]?.toFixed(
                            //   decimalPlaces
                            // )}%`}
                            value={`(${item?.["Lookback Interval"]})`}
                            offset={
                              item?.["Lookback Interval"]?.includes("mo")
                                ? -93.89
                                : item?.["Lookback Interval"]?.length >= 4
                                ? -90.74
                                : -85
                            }
                            position="insideRight"
                            fontSize={13}
                            // style={{ fontWeight: "bold" }}
                            opacity={0.75}
                          />
                        }
                        ifOverflow={"extendDomain"}
                        opacity={0.85}
                      />
                    );
                  })}
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "baseline",
              marginTop: 150,
              marginRight: 20,
            }}
          >
            {calculatingVolatility ? (
              <h3>Calculating...</h3>
            ) : (
              <FinalValue
                heading={"Volatility Lookback Blended Average"}
                value={lookbackVolatilityBlendedAverage}
                decimalPlaces={2}
                isPercent={true}
              />
            )}
          </div>
          <div style={{ marginTop: 10 }}>
            <span>
              <h2>
                Historical Volatility Lookback Data for {companyInfo.Company}{" "}
                (Effective {formatDateString(customEndDate)})
              </h2>
            </span>
            <br />
            <Table
              columns={volatilityLookbackTableColumns}
              dataSource={volatilityLookbackData}
              pagination={{ hideOnSinglePage: true }}
            />
          </div>
        </>
      )}
    </>
  );
}
