import React, { useEffect, useState, useCallback } from "react";
import {
  toDollar,
  customParseFloat,
} from "../../utils/Constants/systemSetting";

export default function SummaryStatTable(props) {
  // const [showCSuite, setShowCSuite] = useState(props.showCSuite);
  // console.log(props.data.length)

  const [csuite, setCSuite] = useState(props.csuite);

  const [custom, setCustom] = useState(0);
  // const [delay, setDelay] = useState(false);
  const [backup, setBackup] = useState([]);
  useEffect(() => {
    if (!props.delay && !props.update) {
      setCSuite(props.csuite);
      setBackup(props.data);
      total = 0;
    }
  }, [props.delay, props.update, props.csuite, props.data]);

  let total = 0;

  // useEffect(() => {
  //   setDelay(true);
  //   const timer = setTimeout(() => {
  //     setDelay(false);
  //   }, 1000);
  //   return () => clearTimeout(timer);
  // }, [props.data]);

  const renderSummaryStatsTable = useCallback(
    (data) => {
      const table = {
        salary: [],
        bonus: [],
        stockAward: [],
        nonEquity: [],
        pension: [],
        optionAward: [],
        other: [],
        total: [],
      };
      if (!props.delay && !props.update) {
        // || props.update
        for (let i = 0; i < data.length; ++i) {
          for (let key in table) {
            let item = data[i].compensation?.[props.currentYear]?.[key];
            if (
              item &&
              parseInt(data[i].compensation?.[props.currentYear]?.["total"]) >
                -1
            ) {
              table[key].push(item);
            }
          }
        }
      }

      const quantile = (sorted, q) => {
        const pos = (sorted.length - 1) * q;
        const base = Math.floor(pos);
        const rest = pos - base;
        if (sorted[base + 1] !== undefined) {
          return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
        } else {
          return sorted[base];
        }
      };

      return Object.entries(table).map(([key, value], index) => {
        const sorted = value.sort((a, b) => a - b);
        const len = sorted.length;
        const total = value.reduce((total, arg) => total + arg, 0);
        const totalCount = value.reduce((totalCount, arg) => {
          return parseInt(arg) > 0 ? totalCount + 1 : totalCount;
        }, 0);
        const min = toDollar(sorted[0]);
        const max = toDollar(sorted[len - 1]);
        const oneQuarter = toDollar(quantile(sorted, 0.25));
        const half = toDollar(quantile(sorted, 0.5));
        const threeQuarter = toDollar(quantile(sorted, 0.75));
        const customPercentile = toDollar(quantile(sorted, custom / 100));
        // console.log(total, len, totalCount);
        // console.log(data);
        return (
          <tr key={index}>
            {key === "salary" && <th>Base Salary</th>}
            {key === "bonus" && <th>Bonus Payout</th>}
            {key === "stockAward" && <th>Stock Awards</th>}
            {key === "nonEquity" && (
              <th>Non-Equity Incentive Plan Compensation</th>
            )}
            {key === "pension" && (
              <th>
                Change in Pension Value & Nonqualified Deferred Compensation
                Earnings
              </th>
            )}
            {key === "optionAward" && <th>Option Awards</th>}
            {key === "other" && <th>All Other Compensation</th>}
            {key === "total" && <th>TOTAL COMPENSATION</th>}
            <td>{min || "N/A"}</td>
            <td className="d-none d-lg-table-cell">{oneQuarter || "N/A"}</td>
            <td className="d-none d-lg-table-cell">{half || "N/A"}</td>
            <td className="d-none d-lg-table-cell">{threeQuarter || "N/A"}</td>
            <td>{max || "N/A"}</td>
            <td>{toDollar(Math.round(total / len)) || "N/A"}</td>
            <td>{customPercentile || "N/A"}</td>
          </tr>
        );
      });
    },
    [custom, props]
  );

  return (
    <div className="summary-stat-table">
      {/* {props.showCSuite && (
                <div className="mb-3">

                    <button
                        className={csuite === "ceo" ? "active" : ""}
                        onClick={() => {
                            setCSuite("ceo");
                        }}
                    >
                        CEO
                    </button>
                    <button
                        className={csuite === "cfo" ? "active" : ""}
                        onClick={() => {}}
                    >
                        CFO
                    </button>
                    <button>COO</button>
                    <button>CTO</button>
                    <select name="csuite">
                        <option value="0">Other</option>
                    </select>
                </div>
            )} */}
      <table>
        <thead>
          <tr className="th-row">
            <th></th>
            <th>Min</th>
            <th className="d-none d-lg-table-cell">25th</th>
            <th className="d-none d-lg-table-cell">50th</th>
            <th className="d-none d-lg-table-cell">75th</th>
            <th>Max</th>
            <th>Average</th>
            <th style={{ width: 100 }}>
              Custom Percentile
              <input
                className="custom"
                type="number"
                name="custom"
                value={custom}
                onChange={(e) => {
                  let value = customParseFloat(e.target.value);
                  if (value < 0) {
                    value = 0;
                  } else if (value > 100) {
                    value = 100;
                  }
                  setCustom(value.toLocaleString());
                }}
              />
            </th>
          </tr>
        </thead>
        <tbody>{renderSummaryStatsTable(backup)}</tbody>
      </table>
    </div>
  );
}
