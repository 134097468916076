import React, { useEffect, useState } from "react";
import StripeContainer from "../components/Stripe/StripeContainer";
import "../css/checkout.css";
import PlansService from "../services/PlansService";
import CouponService from "../services/CouponService";
import CheckoutSteps from "../components/Checkout/checkoutSteps";
import { useDispatch, useSelector } from "react-redux";
import LoginAndSignup from "../components/Login/LoginAndSignup";
import { getAllPlans } from "../redux/actions/PlansAction";
import { Select } from "antd";
import { useHistory } from "react-router-dom";

const { Option } = Select;

export default function Checkout(props) {
  const [plan, setPlan] = useState(null);
  const [coupon, setCoupon] = useState({});
  const { plans } = useSelector((state) => state.PlansReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const [accountEmail, setAccountEmail] = useState("");
  useEffect(() => {
    // load plans if they weren't loaded on homepage
    if (!plans.length) {
      dispatch(getAllPlans());
    }
  }, [plans, dispatch]);
  const [sortedPlans, setSortedPlans] = useState([]);
  useEffect(() => {
    setSortedPlans(
      plans
        .filter((plan) => plan.active)
        .filter((plan) => plan.metadata?.displayOnFrontend === "true")
        .sort((a, b) => {
          return a.price - b.price;
        })
    );
  }, [plans]);

  useEffect(() => {
    PlansService.getPlan(props.match.params.id)
      .then((res) => {
        setPlan(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props, plans]);

  useEffect(() => {
    CouponService.getCoupon(props.match.params.coupon)
      .then((res) => {
        setCoupon(res.data);
      })
      .catch((err) => {
        // console.log(err);
        setCoupon({});
      });
  }, [props, plans]);
  // console.log(coupon);

  const user = useSelector((state) => state.UserReducer);

  const renderPlanName = (plan) => {
    if (plan.interval === "year") {
      return `Annual plan, paid yearly – $${(
        plan.price / 100
      ).toLocaleString()}/yr per license`;
    }
    if (plan.interval === "month") {
      return `Monthly plan, paid monthly – $${(
        plan.price / 100
      ).toLocaleString()}/mo per license`;
    }
    return "Unrecognized plan";
  };

  const handlePlanChange = (value) => {
    history.push(`/checkout/${value}`);
  };

  const renderPrice = () => {
    if (plan?.price) {
      return (
        <div className="cart">
          <div className="title">
            <img
              style={{
                maxWidth: 30,
                marginBottom: "3px",
              }}
              src={process.env.PUBLIC_URL + "/apple-touch-icon.png"}
              alt="Logo"
            />
            <h3>C-Suite Comp&reg; Data Analytics &amp; Visualization Plan</h3>
          </div>

          <div className="selectPlan">
            <p style={{ opacity: 0.6 }}>Commitment</p>
            <Select
              defaultValue={renderPlanName(plan)}
              style={{
                width: "80%",
              }}
              onChange={handlePlanChange}
            >
              {sortedPlans.map((plan, index) => {
                return (
                  <Option value={plan.id} key={plan.id}>
                    {renderPlanName(plan)}
                  </Option>
                );
              })}
            </Select>
          </div>

          <hr />

          <div className="features">
            <ul>
              {plan?.metadata?.desc &&
                plan?.metadata?.desc.map((feature, idx) => {
                  return (
                    <li key={idx}>
                      <i className="fa fa-check"></i> {feature}
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="row prices">
            <div className="col-6 price">
              <p>Subtotal</p>
              {coupon?.name && (
                <p>
                  Discount&nbsp;Applied:&nbsp;
                  <b>{coupon.name?.replace(" ", "")}</b>
                </p>
              )}
              <p>
                <span>DUE NOW</span>
              </p>
            </div>
            <div className="col-6">
              <div className="price" style={{ textAlign: "end" }}>
                <p>
                  ${(plan?.price / 100).toLocaleString()}/
                  {plan?.interval === "year" ? "yr" : "mo"}
                </p>
                {coupon?.amount_off && (
                  <p>-${(coupon.amount_off / 100).toLocaleString()}</p>
                )}
                {coupon?.percent_off && (
                  <p>
                    -$
                    {(
                      (plan?.price * (coupon.percent_off / 100)) /
                      100
                    ).toLocaleString()}
                  </p>
                )}
                {coupon?.amount_off > 0 ? (
                  <p>
                    <span>
                      $
                      {(
                        (plan?.price - coupon?.amount_off) /
                        100
                      ).toLocaleString()}
                    </span>
                    {plan?.interval === "year" ? "yr" : "mo"}
                    <span className="tax"> + tax</span>
                  </p>
                ) : coupon?.percent_off === 100 ? (
                  <p>
                    <span>
                      $
                      {(
                        plan?.price -
                        (plan?.price * (coupon?.percent_off / 100)) / 100
                      ).toLocaleString()}
                    </span>
                  </p>
                ) : (
                  <p>
                    <span>${(plan?.price / 100).toLocaleString()}</span>/
                    {plan?.interval === "year" ? "yr" : "mo"}
                    <span className="tax"> + tax</span>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <div className="cart title">Loading...</div>;
  };

  return (
    <div className="checkout">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 order-2 order-lg-1">
            <div className="payment">
              <StripeContainer
                params={props.match.params.id}
                plan={plan}
                accountEmail={accountEmail}
                setAccountEmail={setAccountEmail}
                coupon={coupon}
              />
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <p className="title" style={{ marginLeft: 10 }}>
              Products for your team:
            </p>
            {renderPrice()}
            <br />
            {accountEmail ? (
              <div
                className="accountEmail text-center mb-3"
                style={{ paddingLeft: 5 }}
              >
                <h3 style={{ fontSize: "1.2em" }}>Email for account login: </h3>
                <h3
                  style={{
                    fontSize: "1.2em",
                    color: "var(--blue)",
                    cursor: "pointer",
                  }}
                  onClick={() => document.getElementById("email").focus()}
                >
                  {accountEmail}
                </h3>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
