import React, { useEffect, useState, useRef } from "react";
import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import Input from "../Input/Input";
import { Button } from "../../JSS/Button";
import { useDispatch, useSelector } from "react-redux";
import PaymentService from "../../services/PaymentService";
import {
  CARD_OPTIONS,
  EMAIL_REGEX,
  PASSWORD_REGEX,
  PUBLIC_INVISIBLE_RECAPTCHA_SITE_KEY,
} from "../../utils/Constants/systemSetting";
import {
  HIDE_LOADING,
  SHOW_LOADING,
} from "../../redux/constants/LoadingConstant";
import PhoneInput from "react-phone-input-2";
import toast, { ToastBar } from "react-hot-toast";
import { Tooltip, Popover, Checkbox } from "antd";
import { Link } from "react-router-dom";
import { signUp } from "../../redux/actions/UserAction";
import { signIn } from "../../redux/actions/UserAction";
import ReCAPTCHA from "react-google-recaptcha";

export default function PaymentForm(props) {
  const { history } = useSelector((state) => state.HistoryReducer);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [showPhone, setShowPhone] = useState(false);
  const [accountEmail, setAccountEmail] = [
    props.accountEmail,
    props.setAccountEmail,
  ];
  const [formValid, setFormValid] = useState(false);
  const [phoneValid, setPhoneValid] = useState(false);
  const [cardValid, setCardValid] = useState(false);
  const isTestAccount = process.env.NODE_ENV === "development";

  const getPlanInterval = (interval) => {
    if (interval === "year") return "annual";
    if (interval === "month") return "month";
    return "loading...";
  };

  const unCamelCase = (str) =>
    str.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
      return str.toUpperCase();
    });

  const reRef = useRef(null);

  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    address: "",
    zip: "",
    companyName: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
    emailConsent: true,
    isTestAccount,
  });
  const notRequired = ["companyName"];

  useEffect(() => {
    if (!accountEmail && state?.email) {
      setAccountEmail(state.email);
    }
  }, [state, accountEmail]);

  const [disabled, setDisabled] = useState(true);

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const [paymentRequest, setPaymentRequest] = useState(null);
  const [missingCardValues, setMissingCardValues] = useState([]);

  useEffect(() => {
    if (
      (cardValid || props?.coupon?.percent_off === 100) &&
      formValid &&
      phoneValid
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [cardValid, formValid, phoneValid, props]);

  const [cardStatus, setCardStatus] = useState({
    cardNumber: {},
    cardCvc: {},
    cardExpiry: {},
  });
  const handleCardChange = (e) => {
    const fieldType = e.elementType;
    const values = { ...cardStatus };
    values[fieldType] = e;
    setCardStatus({ ...values });
  };

  useEffect(() => {
    let valid = true;
    const missingValues = [];
    for (const [key, value] of Object.entries(cardStatus)) {
      if (!value?.complete && props?.coupon?.percent_off !== 100) {
        valid = false;
        missingValues.push(unCamelCase(key));
      }
    }
    setCardValid(valid);
    setMissingCardValues(missingValues);
  }, [cardStatus, props]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const values = { ...state };
    values[name] = value;
    let valid = true;

    if (!EMAIL_REGEX.test(values.email)) {
      setEmailInvalid(true);
      valid = false;
    } else {
      setEmailInvalid(false);
    }
    // if (!PASSWORD_REGEX.test(values.password)) {
    if (name === "password" && value.length < 6) {
      valid = false;
      setPasswordInvalid(true);
    } else {
      setPasswordInvalid(false);
    }
    if (
      values.confirmPassword !== values.password &&
      values.confirmPassword !== ""
    ) {
      valid = false;
      setPasswordsMatch(false);
    } else {
      setPasswordsMatch(true);
    }

    // Adjust autofill for phone, assume US phone (+1-...)
    if (name === "phone" && value.length === 10) {
      values[name] = "1" + value;
    }

    if (name === "phone" && value.length > 11) {
      values["phone"] = value.substring(0, 11);
    }

    if (["firstName", "lastName"].includes(name) && value.length > 25) {
      values[name] = value.substring(0, 25);
    }
    if (
      ["address", "companyName", "email"].includes(name) &&
      value.length > 50
    ) {
      values[name] = value.substring(0, 50);
    }

    if (name === "password" && value.length > 20) {
      values["password"] = value.substring(0, 20);
    }

    if (name === "zip") {
      values["zip"] = value.replace(/[^0-9]/gi, "");
      if (value.length > 5) {
        values["zip"] = value.substring(0, 5);
      }
    }

    for (let key in values) {
      if (key !== "isTestAccount" && key !== "emailConsent") {
        if (values[key].trim() === "" && !notRequired.includes(key)) {
          valid = false;
        }
        if (key === "zip" && values[key].trim().length !== 5) {
          valid = false;
        }
      }
    }
    setFormValid(valid);
    setState({ ...values });
  };
  const handleChangePhone = (phone) => {
    const values = { ...state };
    values["phone"] = phone;
    let valid = true;

    if (values.phone.length !== 11) {
      valid = false;
    }

    setPhoneValid(valid);
    setState({ ...values });
  };
  const [show, toggle] = useState(false);
  const [showConfirm, toggleConfirm] = useState(false);

  const checkoutInput = (name, label, required = true) => {
    return (
      <div className="checkout-input-container">
        <p>{label}</p>
        <div className="input-container">
          <input
            className="checkout-input"
            name={name}
            onChange={handleChange}
            // placeholder={label}
            type={
              (name === "password" && !show) ||
              (name === "confirmPassword" && !showConfirm)
                ? "password"
                : "text"
            }
            style={{
              border:
                (name === "password" &&
                  passwordInvalid &&
                  state.password !== "") ||
                (name === "confirmPassword" &&
                  !passwordsMatch &&
                  !passwordInvalid) ||
                (name === "zip" && state.zip !== "" && state.zip.length < 5) ||
                (name === "email" && state.email !== "" && emailInvalid)
                  ? "1px solid red"
                  : "",
            }}
            value={state[name]}
            onFocus={() => {
              setShowPhone(
                (name === "phone" && !showPhone) ||
                  (name !== "phone" && showPhone)
              );
            }}
            onBlur={() => {
              name === "email" && setAccountEmail(state.email);
            }}
            id={name}
            required={!notRequired.includes(name)}
          />
          {name.toLowerCase().includes("password") ? (
            <div
              onClick={() => {
                name === "password"
                  ? toggle(!show)
                  : toggleConfirm(!showConfirm);
              }}
              className="eye"
            >
              <img
                src={require("../../assets/eye.png").default}
                alt="eye"
                className={
                  (name === "password" && !show) ||
                  (name === "confirmPassword" && !showConfirm)
                    ? "d-none"
                    : "d-block"
                }
              />
              <img
                src={require("../../assets/closed-eye.png").default}
                alt="closed eye"
                className={
                  (name === "password" && show) ||
                  (name === "confirmPassword" && showConfirm)
                    ? "d-none"
                    : "d-block"
                }
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);

    try {
      if (!stripe || !elements) {
        return;
      }
      // stripe.createToken(elements).then(function (result) {
      //   console.log(result.token);
      //   // Handle result.error or result.token
      // });

      const payment =
        props?.coupon?.percent_off === 100
          ? props?.coupon
          : await stripe.createPaymentMethod({
              type: "card",
              card: elements.getElement("cardNumber"),
              billing_details: {
                name: `${state.firstName} ${state.lastName}`,
                email: state.email,
                phone: state.phone,
                address: {
                  postal_code: state.zip,
                },
              },
            });

      if (props?.coupon?.percent_off !== 100 && payment.error) {
        toast.error(payment.error.message);
        setDisabled(false);
      } else {
        dispatch({
          type: SHOW_LOADING,
        });

        const paymentDetails = {
          payment_method:
            props?.coupon?.percent_off === 100
              ? payment
              : payment.paymentMethod.id,
          plan: props.params,
          coupon: props.coupon,
        };

        try {
          reRef.current.reset();
          const token = await reRef.current.executeAsync();

          const newUser = {
            ...state,
            fname: state.firstName,
            lname: state.lastName,
            token,
          };
          const { data } = await PaymentService.createCustomer({
            userDetails: newUser,
            paymentDetails,
          });
          // console.log(JSON.stringify(data));

          if (
            data.stripeResponse.status !== "succeeded" &&
            data.stripeResponse.total !== 0
          ) {
            stripe
              .confirmCardPayment(data.stripeResponse.client_secret, {
                receipt_email: state.email,
              })
              .then((res) => {
                if (res.error) {
                  console.log(res.error);
                  toast.error("Payment failed. Please try again later.");
                  setDisabled(false);
                } else {
                  toast.success("Thank you for your Subscription!");
                  dispatch(
                    signIn({ email: state.email, password: state.password })
                  );
                  history.push("/");
                }
              });
          } else {
            toast.success("Thank you for your Subscription!");
            dispatch(signIn({ email: state.email, password: state.password }));
            history.push("/");
          }
        } catch (err) {
          // console.log(err);
          // console.log(err?.response);
          if (
            err?.response?.data?.raw?.code === "customer_tax_location_invalid"
          ) {
            toast.error(
              "Zip code is not valid. Please enter a valid zip code."
            );
          } else {
            toast.error(
              typeof err?.response?.data === "string"
                ? err.response.data
                : typeof err?.response?.data?.raw?.message === "string"
                ? `Failed to create account. ${err.response.data.raw.message}`
                : "Cannot create new account right now. Please try again later."
            );
          }
        }
        setDisabled(false);
      }
    } catch (err) {
      console.log(err.response || err);
    } finally {
      dispatch({
        type: HIDE_LOADING,
      });
    }
  };

  const getMissingValues = () => {
    const missingValues = [];
    for (const [key, value] of Object.entries(state)) {
      if (value === "") {
        if (!notRequired.includes(key)) {
          missingValues.push(unCamelCase(key));
        }
      }
    }
    const allMissingValues = missingCardValues.concat(missingValues);
    return allMissingValues;
  };

  const getInvalidValues = () => {
    const invalidValues = [];
    for (const [key, value] of Object.entries(state)) {
      if (
        (key === "zip" && value.length < 5) ||
        (key === "phone" && value.length < 11) ||
        (key === "password" && value.length < 6) ||
        (key === "confirmPassword" && state.password !== value) ||
        (key === "email" && !EMAIL_REGEX.test(value))
      ) {
        invalidValues.push(unCamelCase(key));
      }
    }
    return invalidValues;
  };

  return (
    <div className="payment-form">
      <div className="title" style={{ marginBottom: 10 }}>
        <p>Add payment method and create user account:</p>
      </div>

      <div className="payment-button"></div>

      <form onSubmit={handleSubmit} className="payment-card">
        <div id="card-element">
          <p>Credit/debit card number</p>
          <Tooltip
            overlayStyle={{ maxWidth: "345px" }}
            visible={cardStatus.cardNumber?.error?.message ? true : false}
            overlayInnerStyle={{
              borderRadius: "5px",
              color: "var(--white)",
              backgroundColor: "var(--dark-red)",
            }}
            title={cardStatus.cardNumber?.error?.message}
            placement="top"
          >
            <CardNumberElement
              options={CARD_OPTIONS}
              className="stripe-input"
              id="cardNumber"
              onChange={handleCardChange}
            />
          </Tooltip>
        </div>
        <div className="row">
          <div className="col-6">
            <p>Expiration date</p>
            <Tooltip
              overlayStyle={{ maxWidth: "345px" }}
              visible={cardStatus.cardExpiry?.error?.message ? true : false}
              overlayInnerStyle={{
                borderRadius: "5px",
                color: "var(--white)",
                backgroundColor: "var(--dark-red)",
              }}
              title={cardStatus.cardExpiry?.error?.message}
              placement="top"
            >
              <CardExpiryElement
                options={CARD_OPTIONS}
                className="stripe-input"
                id="cardExpiration"
                onChange={handleCardChange}
              />
            </Tooltip>
          </div>
          <div className="col-6">
            <p>Security code</p>
            <Tooltip
              overlayStyle={{ maxWidth: "345px" }}
              visible={cardStatus.cardCvc?.error?.message ? true : false}
              overlayInnerStyle={{
                borderRadius: "5px",
                color: "var(--white)",
                backgroundColor: "var(--dark-red)",
              }}
              title={cardStatus.cardCvc?.error?.message}
              placement="right"
            >
              <CardCvcElement
                options={CARD_OPTIONS}
                className="stripe-input"
                id="securityCode"
                onChange={handleCardChange}
              />
            </Tooltip>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            {checkoutInput("firstName", "First name")}
          </div>
          <div className="col-6">{checkoutInput("lastName", "Last name")}</div>
        </div>
        {checkoutInput("address", "Billing address")}
        <div className="row">
          <Tooltip
            overlayStyle={{ maxWidth: "345px" }}
            visible={state.zip !== "" && state.zip.length < 5}
            overlayInnerStyle={{
              borderRadius: "5px",
              color: "var(--white)",
              backgroundColor: "var(--dark-red)",
            }}
            title="Zip code is too short."
            placement="left"
          >
            <div className="col-6" style={{ height: 90 }}>
              {checkoutInput("zip", "Zip code")}
            </div>
          </Tooltip>
          {/* <div className="col-6">{checkoutInput("phone", "Phone number")}</div> */}
          <div className="col-6">
            {showPhone ? (
              <>
                <p style={{ marginBottom: -10, paddingTop: 10 }}>
                  Phone number
                </p>
                <div style={{ width: "100%" }}>
                  <PhoneInput
                    name="phone"
                    placeholder="Phone Number"
                    country="us"
                    preferredCountries={["us"]}
                    onlyCountries={["us"]}
                    onChange={(phone) => handleChangePhone(phone)}
                    onBlur={() => (!state.phone ? setShowPhone(false) : "")}
                    value={state.phone}
                    inputProps={{
                      autoFocus: true,
                    }}
                    inputStyle={{
                      borderRadius: "0.5rem",
                      // border: `1px solid ${state.errors.phone}`,
                      // borderColor: "var(--secondary)",
                      width: "100%",
                      padding: "0px 12px 0px 48px",
                      // marginBottom: "10px",
                      // marginTop: "20px",
                      fontSize: "16px",
                      height: "40px",
                      autoFocus: true,
                    }}
                    containerStyle={{ marginTop: "15px" }}
                  />
                </div>
              </>
            ) : (
              checkoutInput("phone", "Phone number")
            )}
          </div>
        </div>
        {checkoutInput("companyName", "Company name (optional)", false)}
        <Tooltip
          overlayStyle={{ maxWidth: "345px", marginTop: 40 }}
          visible={emailInvalid && state.email !== ""}
          overlayInnerStyle={{
            borderRadius: "5px",
            color: "var(--white)",
            backgroundColor: "var(--dark-red)",
          }}
          title={
            emailInvalid && state.email !== ""
              ? "Please enter a valid email address"
              : ""
          }
          placement="right"
          arrowPointAtCenter={true}
        >
          <div style={{ height: "110%" }}>
            {checkoutInput(
              "email",
              "Email (serves as your login & resetting password if you forget login)"
            )}
          </div>
        </Tooltip>
        <Tooltip
          overlayStyle={{ maxWidth: "345px" }}
          visible={passwordInvalid && state.password !== ""}
          overlayInnerStyle={{
            borderRadius: "5px",
            color: "var(--white)",
            backgroundColor: "var(--dark-red)",
          }}
          title={passwordInvalid ? "Password is too short." : ""}
          placement="right"
        >
          <div>{checkoutInput("password", "Create Password")}</div>
        </Tooltip>
        <Tooltip
          overlayStyle={{ maxWidth: "345px" }}
          visible={!passwordsMatch && !passwordInvalid}
          overlayInnerStyle={{
            borderRadius: "5px",
            color: "var(--white)",
            backgroundColor: "var(--dark-red)",
          }}
          title={!passwordsMatch ? "Passwords do not match" : ""}
          placement="right"
          arrowPointAtCenter={true}
        >
          <div style={{ height: 90 }}>
            {checkoutInput("confirmPassword", "Confirm Password")}
          </div>
        </Tooltip>

        <Checkbox
          checked={state.emailConsent}
          onChange={() =>
            setState({ ...state, emailConsent: !state.emailConsent })
          }
          style={{ fontSize: "0.8em", lineHeight: "1.4em" }}
          inputStyle={{ marginTop: "100px" }}
        >
          I want to get updates on C-level compensation changes, regulatory
          changes, and new product features via occasional email. Your
          information will never be shared.
        </Checkbox>

        {/* <Input onChange={handleChange} name="email" label="Email" required /> */}
        <div className="checkoutDisclaimer">
          <p>
            By clicking "Agree and subscribe," you agree to be charged{" "}
            {props.plan?.price
              ? `$${(
                  props.plan?.price / 100
                ).toLocaleString()} ${getPlanInterval(props.plan.interval)}ly`
              : "the listed price"}{" "}
            (plus tax) and your subscription will automatically renew{" "}
            {getPlanInterval(props?.plan?.interval)}ly until you cancel (price
            subject to change). Cancel anytime via{" "}
            <Link to="/contact" target="_blank" rel="noopener noreferrer">
              Customer Support.
            </Link>{" "}
            You also agree to the{" "}
            <Link to="/privacy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </Link>{" "}
            and{" "}
            <Link to="/terms" target="_blank" rel="noopener noreferrer">
              Terms of Use.
            </Link>
          </p>
        </div>
        <div className="d-flex justify-content-start place-order">
          <Popover
            overlayStyle={{
              maxWidth: "345px",
              visibility:
                !getMissingValues().length && !getInvalidValues().length
                  ? "hidden"
                  : "",
            }}
            placement="right"
            html={true}
            overlayInnerStyle={{
              borderRadius: "5px",
              color: "var(--white)",
              backgroundColor: "var(--light-1)",
            }}
            content={
              getMissingValues().length || getInvalidValues().length ? (
                <>
                  {getMissingValues().length ? (
                    <div>
                      <p>Please complete all required fields.</p>
                      <p>Currently missing:</p>
                      <ul>
                        {getMissingValues().map((val) => {
                          return <li>{val}</li>;
                        })}
                      </ul>{" "}
                    </div>
                  ) : (
                    <div>
                      <p>Please correct the following fields:</p>
                      <p>Currently invalid:</p>
                      <ul>
                        {getInvalidValues().map((val) => {
                          return <li>{val}</li>;
                        })}
                      </ul>{" "}
                    </div>
                  )}
                </>
              ) : null
            }
          >
            <div
              style={{
                cursor: disabled ? "not-allowed" : "auto",
                visibility: disabled ? "visible" : "hidden",
              }}
            >
              <Button
                disabled={disabled}
                type="submit"
                style={{
                  pointerEvents: disabled ? "none" : "auto",
                  visibility: "visible",
                }}
                primary
              >
                Agree and Subscribe
              </Button>
            </div>
          </Popover>
          <Tooltip
            overlayStyle={{ fontSize: "0.8em" }}
            title="C-Suite Comp is serious about protecting your personal information. 
            To ensure your account details are safe, we use Secure Sockets Layer (SSL), 
            an industry standard for encrypting private data over the Internet."
          >
            <img
              src={require("../../assets/lock.svg").default}
              style={{ width: "20px", marginLeft: 10, position: "relative" }}
              alt="lock icon"
            />
          </Tooltip>
        </div>
      </form>
      <ReCAPTCHA
        sitekey={PUBLIC_INVISIBLE_RECAPTCHA_SITE_KEY}
        size="invisible"
        ref={reRef}
        // onChange={handleCaptchaChange}
      />
    </div>
  );
}
