import React, { Suspense } from "react";
import { useEffect, useState, useRef } from "react";
import "../css/homepage.css";
import Loading from "../components/LoadingScreen/Loading";
import { LinkButton } from "../JSS/LinkButton";
import { getSubscription } from "../redux/actions/CustomerAction";
import { useDispatch, useSelector } from "react-redux";
// import { getAllExecutives } from "../redux/actions/ExecutivesAction";
import Paywall from "../components/Paywall/Paywall";
import { displayPaywall } from "../redux/actions/PaywallAction";
import { checkTableUseLimit } from "../redux/actions/UserAction";
// import CSuiteTable from "../components/Table/CSuiteTable";
import scrollArrow from "../assets/expand-arrow.png";
import { executivesTotal } from "../utils/Constants/executiveCount.js";
import { boardMembersTotal } from "../utils/Constants/boardMemberCount.js";
import { getAllPlans } from "../redux/actions/PlansAction";
import { store } from "../redux/reducers/configStore";
import { HIDE_LOADING } from "../redux/constants/LoadingConstant";
import { SHOW_PAYWALL } from "../redux/constants/PaywallConstant";
import { Link } from "react-router-dom";
import CSuiteTable from "../components/Table/CSuiteTable";
import demo1 from "../assets/mp4/demo1.mp4";
import demo2 from "../assets/mp4/demo2.mp4";
import demo3 from "../assets/mp4/demo3.mp4";
import demo4 from "../assets/mp4/demo4.mp4";
import demo5 from "../assets/mp4/demo5.mp4";
import FeaturedIn from "../components/FeaturedIn";
import TrustedBy from "../components/TrustedBy.js";

// const CSuiteTable = React.lazy(() => import("../components/Table/CSuiteTable"));
export default function Homepage() {
  const cTableRef = useRef(null);
  const scrollToRef = (itemRef) => {
    itemRef.current.scrollIntoView({
      behavior: "auto",
    });
  };
  const dispatch = useDispatch();

  const { subscription } = useSelector((state) => state.CustomerReducer);

  const [isActiveSubscriber, setIsActiveSubscriber] = useState(
    ["active", "trialing"].includes(subscription?.status)
  );
  const [isMobile, setIsMobile] = useState(
    window.navigator.userAgentData?.mobile ||
      window.navigator.userAgent.includes("Mobile")
  );
  useEffect(() => {
    setIsActiveSubscriber(
      ["active", "trialing"].includes(subscription?.status)
    );
    setIsMobile(
      window.navigator.userAgentData?.mobile ||
        window.navigator.userAgent.includes("Mobile")
    );
  }, [subscription]);
  useEffect(() => {
    dispatch(getSubscription());
  }, []);

  useEffect(() => {
    //pre-load subscription plans
    dispatch(getAllPlans());
  }, []);

  const { showPaywall, allow } = useSelector((state) => state.PaywallReducer);

  // console.log(showPaywall, allow);

  const [paywallDisplayed, setPaywallDisplayed] = useState(false);
  useEffect(() => {
    if (subscription) {
      if (!isActiveSubscriber && !paywallDisplayed) {
        dispatch({
          type: HIDE_LOADING,
        });
        dispatch({
          type: SHOW_PAYWALL,
        });
        setPaywallDisplayed(true);
      }
    }
  }, [
    subscription,
    showPaywall,
    isActiveSubscriber,
    paywallDisplayed,
    dispatch,
  ]);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  return (
    <div className="homepage">
      {showPaywall && <Paywall />}
      <section
        className="intro"
        style={{
          minHeight: "500px",
          maxHeight: "550px",
          background:
            "linear-gradient(171deg, var(--white) 59%, var(--primary) 0)",
          maxWidth: isActiveSubscriber ? "1785px" : null,
          marginBottom: isActiveSubscriber ? "10px" : "0px",
        }}
      >
        <div
          className="container"
          style={{
            maxWidth: isActiveSubscriber ? "950px" : null,
          }}
        >
          <div className="row">
            <div className="col-xl-7 col-12 intro-text">
              <img
                className="logo"
                style={{
                  maxWidth: 350,
                  width: "63%",
                  marginBottom: "10px",
                  marginLeft: "20px", // centers logo with TM
                }}
                src="/logos/CSuiteLogo(R).svg"
                alt="Logo"
              />
              <p className="slogan mb-3" style={{ textAlign: "center" }}>
                Easily analyze, slice, dice, and visualize executive pay data
                for thousands of companies and over{" "}
                {(Math.floor(executivesTotal / 1000) * 1000).toLocaleString()}{" "}
                named executives and{" "}
                {(Math.floor(boardMembersTotal / 1000) * 1000).toLocaleString()}{" "}
                board members.
              </p>
              {!isActiveSubscriber && (
                <div className="button-group">
                  <Link to="/request-demo">
                    <LinkButton href="/request-demo" className="me-3" primary>
                      Request a Free Demo
                    </LinkButton>
                  </Link>
                  <Link to="/about">
                    <LinkButton>Learn More</LinkButton>
                  </Link>
                </div>
              )}
            </div>
            <div className="col-xl-6 col-12 intro-img"></div>
          </div>
        </div>
      </section>

      {!isActiveSubscriber ? (
        <div className="about" style={{ paddingTop: 0 }}>
          <FeaturedIn />
          <TrustedBy />
          <div className="container main mw-2000" style={{ paddingTop: 50 }}>
            <div className="container section centered">
              <div className="row">
                <div className=" col-md centered">
                  <p className="description mt-0">
                    C-Suite Comp has the most advanced state of the art engine
                    in the executive compensation industry for algorithmically
                    gathering, validating, mining, and presenting executive
                    compensation data from primary sources. Enter any of a
                    myriad filter choices to get corresponding data instantly.
                  </p>
                </div>
                <div className="col-md centered">
                  <video
                    autoPlay
                    muted
                    loop
                    playsInline
                    className="img-fluid circle"
                    alt="demo_video_1"
                    style={{ marginTop: "2vh" }}
                  >
                    <source src={demo1} type="video/mp4" />
                    Your browser does not support HTML5 video.
                  </video>
                </div>
              </div>
            </div>
            <div className="container section centered">
              <div className="row">
                <div className="col-md centered order-2 order-md-1">
                  <video
                    autoPlay
                    muted
                    loop
                    playsInline
                    className="img-fluid circle"
                    alt="demo_video_2"
                    style={{ marginTop: "2vh" }}
                  >
                    <source src={demo2} type="video/mp4" />
                    Your browser does not support HTML5 video.
                  </video>
                </div>
                <div className="col-md centered order-1 order-md-2">
                  <p className="description">
                    Search one of thousands of companies for a detailed view on
                    specific pay structures, compensation trends, and
                    performance by executive.
                  </p>
                </div>
              </div>
            </div>
            <div className="container section centered">
              <div className="row">
                <div className=" col-md centered">
                  <p className="description">
                    Run your custom searches and easily export and embed the
                    resultant data and visuals into compensation reports.
                  </p>
                </div>
                <div className="col-md centered">
                  <video
                    autoPlay
                    muted
                    loop
                    playsInline
                    className="img-fluid circle"
                    alt="demo_video_3"
                    style={{ marginTop: "2vh" }}
                  >
                    <source src={demo3} type="video/mp4" />
                    Your browser does not support HTML5 video.
                  </video>
                </div>
              </div>
            </div>
            <div className="container section centered">
              <div className="row">
                <div className="col-md centered order-2 order-md-1">
                  {" "}
                  <video
                    autoPlay
                    muted
                    loop
                    playsInline
                    className="img-fluid circle"
                    alt="demo_video_4"
                    style={{ marginTop: "2vh" }}
                  >
                    <source src={demo4} type="video/mp4" />
                    Your browser does not support HTML5 video.
                  </video>
                </div>
                <div className="col-md centered order-1 order-md-2">
                  <p className="description">
                    Intuitive graphs and charts that respond to your specific
                    queries instantly. Drill down compensation bands, view
                    trends, and examine compensation component weights for each
                    band.
                  </p>
                </div>
              </div>
            </div>
            <div className="container section centered">
              <div className="row">
                <div className=" col-md centered">
                  <p className="description">
                    Create custom peer groups for benchmarking.
                  </p>
                </div>
                <div className="col-md centered">
                  <video
                    autoPlay
                    muted
                    loop
                    playsInline
                    className="img-fluid circle"
                    alt="demo_video_5"
                    style={{ marginTop: "2vh" }}
                  >
                    <source src={demo5} type="video/mp4" />
                    Your browser does not support HTML5 video.
                  </video>
                </div>
              </div>
            </div>

            <div className="container section pt-5">
              <p>
                <b>Who is this for? </b>
                <span>
                  CHROs, VPs Executive Compensations, VPs Total Rewards,
                  Compensation Directors, Compensation Analysts, Private Equity
                  firms, and C-Level officers of both publicly traded and
                  private companies.
                </span>
              </p>
            </div>

            <div className="container section ">
              <p>
                <b>How are we different? </b>
                <span>
                  Our most salient differentiators are data quality, ease of
                  use, and data volume. We are unsurpassed in the industry in
                  data quality (benchmarked against competitors annually). Our
                  synchronized primary data, analytical engine, and
                  visualization engine makes it super easy for clients to answer
                  what used to be difficult to extract or time consuming. With
                  our highly intuitive interface you can ask our platform an
                  unbounded variety of questions and get instant answers which
                  you can embed and share within your internal organization in
                  seconds. There is no cap on the number of queries you can
                  make. This is a self-service platform so you can use it any
                  time anywhere unhindered. You no longer have to wait days or
                  weeks on your executive compensation consultant to get answers
                  to questions that pop up throughout the fiscal year.{" "}
                </span>
              </p>
            </div>
            <div className="container section">
              <p>
                <b>What problems do we solve? </b>
                <span>
                  If you face challenges with any of the items below you are a
                  natural fit for our platform:
                </span>
                <ul>
                  <li>
                    The new SEC Pay-versus-performance disclosure mandates
                  </li>
                  <li>Timely preparation for disclosures</li>
                  <li>Selecting appropriate financial performance metrics</li>
                  <li>
                    Selecting and experimenting with the best-fit compensation
                    peer group
                  </li>
                  <li>Identifying the appropriate performance peer group</li>
                  <li>
                    Keeping your Board abreast of information pertinent to
                    complying with upcoming proxy seasons
                  </li>
                  <li>
                    Understanding in-depth how your executives compare with
                    their peers
                  </li>
                  <li>
                    Staying current on the latest compensation data and
                    significant changes across companies
                  </li>
                  <li>
                    Re-aligning compensations of your incumbent officers to
                    prevailing market standards
                  </li>
                  <li>
                    Knowing what to offer C-level candidates to make the package
                    competitive
                  </li>
                  <li>
                    Understanding what and how micro and macroeconomic factors
                    influence components of compensation
                  </li>
                  <li>
                    Significantly reducing reliance on external board advisory
                    firms and independent compensation consulting firms – no
                    wait times and instant answers provided
                  </li>
                  <li>
                    Creating your own decks / presentations for executive
                    management, the Compensation Committee, and board meetings
                  </li>
                  <li>
                    Understanding board pay and interlocking board positions
                  </li>
                  <li>
                    Computing numbers for reporting using myriad calculators
                    like option awards calculators, TSR calculators, performance
                    award calculators and dozens more
                  </li>
                  <li>
                    Finding out what Company Selected Metrics your competitors
                    are using and which metric your firm should choose to best
                    position your executive
                  </li>
                  <li>
                    Analyzing your executives’ pay compared to your company’s
                    peer group or a series of hypothetical new peer groups
                  </li>
                  <li>
                    Filtering pay comparisons in a myriad of ways by selecting
                    desired peers, company operating metrics, and financial
                    metrics across time
                  </li>
                </ul>
              </p>
            </div>
            <div className="container section">
              <p>
                <b>How does our product work? </b>
                <span>
                  A subscription allows your team unfettered access to all the
                  compensation data, analysis, and visualizations in our super
                  intuitive platform. You will get access to aggregate views,
                  company level data, and executive level data of named officers
                  along with the accompanying analysis. You will be able to run
                  your own analysis within our platform. Several useful
                  calculators and an unlimited combination of queries get you
                  answers instantly. <Link to="/contact">Contact us</Link> today
                  to schedule a <Link to="/request-demo">live demo</Link> and
                  find out why Fortune 500 clients and the largest private
                  companies love our platform.
                </span>
              </p>
            </div>
            <div className="container section centered">
              <div
                className="col-md centered CTA"
                style={{
                  flexWrap: "wrap",
                  alignContent: "center",
                  // marginBottom: "1vh",
                  marginTop: 50,
                }}
              >
                <p
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    textAlign: "center",
                  }}
                >
                  Discover all that C-Suite Comp has to offer today!
                </p>
                <Link to="/request-demo">
                  <LinkButton primary>Request Demo</LinkButton>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : isMobile ? (
        <div className="mobileWarning" style={{ textAlign: "center" }}>
          <div className="container">
            <h3>Thank you for subscribing to C-Suite Comp. </h3>
            <p>
              {" "}
              C-Suite Comp data analysis is not yet available on mobile devices.
              Please access your account from a desktop computer.
            </p>
            <br />
            <p>
              {" "}
              However, you can still edit your{" "}
              <Link to="/profile">Profile</Link> and{" "}
              <Link to="/membership">Membership</Link> settings.
            </p>
            <br />
          </div>
        </div>
      ) : (
        <>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              opacity: 1,
            }}
          >
            <div
              className="moveupdown"
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                maxWidth: "190px",
                position: "relative",
                top: "min(-6vh, -85px)",
                cursor: "pointer",
              }}
              onClick={() => scrollToRef(cTableRef)}
            >
              <p style={{ fontWeight: "bold" }}>Scroll down to try it now</p>
              <img
                src={scrollArrow}
                style={{ maxHeight: "50px", maxWidth: "50px" }}
                alt="scroll arrow"
              />
            </div>
          </div> */}
          <div style={{ paddingTop: "60px" }}></div>
          <div
            style={{ display: "block", postition: "absolute", top: "-500px" }}
            ref={cTableRef}
          ></div>
          <section id="c-table" style={{ userSelect: "none" }}>
            <Suspense fallback={<Loading />}>
              <CSuiteTable />
            </Suspense>
            {/* <div
              onClick={() => {
                dispatch(displayPaywall());
                dispatch(checkTableUseLimit());
              }}
              className={`c-table-overlay ${!allow ? "active" : ""}`}
            ></div> */}
          </section>
        </>
      )}
    </div>
  );
}
