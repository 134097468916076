import { CSuitePublicBaseService } from "./CSuitePublicBaseServices";

class CompanyService extends CSuitePublicBaseService {
  getAllCompanies = () => {
    return this.get(`data/companyData.json?nocache=${new Date().getHours()}`);
  };

  getAllExecutives = () => {
    return this.get(`data/executiveData.json?nocache=${new Date().getHours()}`);
  };

  getAllMarketCap = () => {
    return this.get(`data/marketCapData.json?nocache=${new Date().getHours()}`);
  };
}

export default new CompanyService();
