import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select, Checkbox, Input, Tooltip } from "antd";
import generateInitFilter from "../../utils/DataFilter/generateInitFilter.js";
import dataFilter from "../../utils/DataFilter/dataFilter.js";
import TableFilter from "../Table/TableFilter.js";
import Sector from "../../assets/NasdaqSectors.json";
import Industries from "../../assets/NasdaqIndustries.json";
import SICCodes from "../../assets/allSICCodes.json";
import {
  customParseFloat,
  formatMeasure,
  DEFAULT_YEAR,
  emdash,
  MIN_YEAR,
} from "../../utils/Constants/systemSetting.js";
import {
  resetTableFilter,
  setTableFilter,
} from "../../redux/actions/TableFilterAction.js";
import { measureOptions } from "../../utils/ExecutiveData/executiveMeasure.js";

import { initFilter } from "../../utils/DataFilter/initFilter.js";
// const initFilter = generateInitFilter();

export default function PeerGroupsByMetricsTable({
  setCustomCohortTickers,
  setNoActiveFilters,
  filter,
  setFilter,
}) {
  const dispatch = useDispatch();
  const { Option } = Select;

  const { companies, marketCap } = useSelector(
    (state) => state.CompaniesReducer
  );
  const companiesValues = Object.values(companies);

  // const { filter: reduxFilter } = useSelector(
  //   (state) => state.TableFilterReducer
  // );

  const [searchVal, setSearchVal] = useState("");
  const [update, setTriggerUpdate] = useState(false);

  const [newValidTickers, setNewValidTickers] = useState([]);
  const [newValidCohorts, setNewValidCohorts] = useState([]);
  const [newValidCities, setNewValidCities] = useState([]);
  const [newValidStates, setNewValidStates] = useState([]);
  const [filteredIndustries, setFilteredIndustries] = useState(Industries);
  const [filteredSICs, setFilteredSICs] = useState(SICCodes);
  const [companyRankCompanies, setCompanyRankCompanies] = useState([]);

  const [indexTickers, setIndexTickers] = useState({});

  const [filteredCompanies, setFilteredCompanies] = useState([]);
  useEffect(() => {
    if (!update) {
      if (filter.indexSelected === "All") {
        const companiesTickers = Object.keys(companies);
        setFilteredCompanies(companiesTickers);
      } else {
        setFilteredCompanies(filter.index); //?.[filter.currentYear].Tickers);
      }
    }
  }, [update, filter, companies]);
  const [filteredMarketCap, setFilteredMarketCap] = useState(new Map([]));
  useEffect(() => {
    const newFilteredMarketCap = marketCap.filter((mCap) =>
      filteredCompanies.includes(mCap?.Ticker)
    );

    setFilteredMarketCap(
      new Map(newFilteredMarketCap.map((mCap, index) => [mCap.Ticker, index]))
    );
  }, [filteredCompanies, marketCap]);

  // To reset filter on every page/subpage change:
  // useEffect(() => {
  //   setFilter(initFilter);
  // }, [setFilter]);

  const FilterComponent = ({
    heading,
    children,
    filterKey,
    hoverText = "",
  }) => {
    return (
      <div
        className="cohort-selector"
        style={{
          display: "flex",
          // alignItems: "start",
          // justifyContent: "start",
          marginTop: 10,
        }}
      >
        <Tooltip title={hoverText}>
          <div className="title" style={{ cursor: hoverText ? "help" : "" }}>
            <h3
              style={{
                fontSize: 20,
                marginRight: 10,
                marginBottom: 5,
                marginTop: 5,
              }}
            >
              {" "}
              {heading.split(" ").map((head, index) => (
                <span key={index}>{head}&nbsp;</span>
              ))}
            </h3>
          </div>
        </Tooltip>
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          // dropdownStyle={{ height: "500px" }}
          // listHeight="100%"
          loading={children?.length < 1}
          allowClear
          value={filter?.[filterKey]}
          onChange={(value) => {
            const values = { ...filter };
            values[filterKey] = value;
            setFilter({ ...values });
            // dispatch(setTableFilter({ ...values }));
            // setTriggerUpdate(true);
            setSearchVal("");
          }}
        >
          {children}
        </Select>
      </div>
    );
  };

  const handleChangeRange = (e, dataType) => {
    const { name } = e.target;
    const values = { ...filter };

    const value = e.target.value.replaceAll(",", "");

    const reg = /^[0-9,.]+$/;
    const neg = /^0$|^-?[0-9]\d*(\.\d+)?$/;
    const dec = /^[0]\d*?(\.\d+)?$/;

    const filterInput = (input) => {
      let newInput = input.replace(/[^0-9.-]/g, "").slice(0, 15);
      if (newInput.split(".").length > 2) {
        newInput = newInput.split(".").slice(0, 2).join(".");
      }
      return newInput;
    };

    const formatValue = (val, dataType) => {
      if (!val || val === emdash || val === "N/A" || val === "0" || val === "-")
        return emdash;
      if (dataType === "dollar") {
        return formatMeasure(
          parseFloat(val?.toString()?.replaceAll(",", "")),
          "dollar"
        )
          ?.toString()
          ?.replace("$", "");
      } else if (dataType) {
        return formatMeasure(
          parseFloat(val?.toString()?.replaceAll(",", "")),
          dataType
        );
      }
      return val;
    };

    //TODO: Use formatValue, or add a case for each datatype as applicable, "" means regular format,
    //but if a datatype is provided it can be formatted differently?

    //these data types can be negitive
    const canBeNegitive = ["netIncome", "revenueGrowthPercent", "OCF_Assets"];
    if (canBeNegitive.some((item) => name.includes(item))) {
      if (value.trim() === "") {
        values[canBeNegitive.find((item) => name.includes(item))][name] = "";
      } else if (value.trim() === "-") {
        values[canBeNegitive.find((item) => name.includes(item))][name] = "-";
      } else {
        if (neg.test(Number(value))) {
          values[canBeNegitive.find((item) => name.includes(item))][name] =
            parseInt(value).toLocaleString();
        }
      }
    }

    if (
      name.includes("percentile") ||
      reg.test(Number(value)) ||
      (dataType && formatValue(value, dataType) !== emdash)
    ) {
      if (name.includes("salary")) {
        if (value.trim() === "" || values < 0) {
          values.salary[name] = "";
        } else {
          values.salary[name] = parseInt(value).toLocaleString();
        }
      } else if (name.includes("percentile")) {
        if (value.trim() === "") {
          values.percentile[name] = "";
        } else if (value < 0) {
          values.percentile[name] = 0;
        } else if (value > 100) {
          values.percentile[name] = 100;
        } else {
          values.percentile[name] = customParseFloat(value).toLocaleString();
        }
      } else if (name.includes("marketCap")) {
        if (value.trim() === "" || values < 0) {
          values.marketCap[name] = "";
        } else {
          values.marketCap[name] = parseInt(value).toLocaleString();
        }
      } else if (name.includes("employees")) {
        if (value.trim() === "" || values < 0) {
          values.employees[name] = "";
        } else {
          values.employees[name] = parseInt(value).toLocaleString();
        }
      } else if (name.includes("companyRank")) {
        if (value.trim() === "" || values < 0) {
          values.companyRank[name] = "";
        } else if (
          parseInt(value) > Object.keys(companies).length ||
          (filter.indexSelected !== "All" &&
            parseInt(value) > filter.index.length)
        ) {
          //?.[filter.currentYear].Tickers.length)
          values.companyRank[name] =
            filter.indexSelected === "All"
              ? Object.keys(companies).length?.toLocaleString()
              : filter.index.length?.toLocaleString(); //?.[filter.currentYear].Tickers.length?.toLocaleString();
        } else {
          values.companyRank[name] = parseInt(value).toLocaleString();
        }
      } else if (name.includes("payRatio")) {
        if (value.trim() === "" || values < 0) {
          values.payRatio[name] = "";
        } else if (parseInt(value) > 1e5) {
          values.payRatio[name] = (1e5).toLocaleString();
        } else {
          values.payRatio[name] = parseInt(value).toLocaleString();
        }
      } else if (
        name.includes("revenue") &&
        !name.includes("revenueGrowthPercent")
      ) {
        if (value.trim() === "" || values < 0) {
          values.revenue[name] = "";
        } else {
          values.revenue[name] = parseInt(value).toLocaleString();
        }
      } else if (name.includes("netIncome")) {
        if (value.trim() === "") {
          values.netIncome[name] = "";
        } else {
          values.netIncome[name] = parseInt(value).toLocaleString();
        }
      } else if (name.includes("assets")) {
        if (value.trim() === "" || values < 0) {
          values.assets[name] = "";
        } else {
          values.assets[name] = parseInt(value).toLocaleString();
        }
      } else if (name.includes("ROA")) {
        if (value.trim() === "" || values < 0) {
          values.ROA[name] = "";
        } else {
          values.ROA[name] = parseInt(value).toLocaleString();
        }
      } else if (name.includes("ROE")) {
        if (value.trim() === "" || values < 0) {
          values.ROE[name] = "";
        } else {
          values.ROE[name] = parseInt(value).toLocaleString();
        }
      } else if (name.includes("grossProfit")) {
        if (value.trim() === "" || values < 0) {
          values.grossProfit[name] = "";
        } else {
          values.grossProfit[name] = parseInt(value).toLocaleString();
        }
      } else if (name.includes("OCF_Assets")) {
        if (value.trim() === "") {
          values.OCF_Assets[name] = "";
        } else {
          values.OCF_Assets[name] = filterInput(value); //formatValue(value, dataType);
        }
      } else if (name.includes("revenueGrowthPercent")) {
        if (value.trim() === "") {
          values.revenueGrowthPercent[name] = "";
        } else {
          values.revenueGrowthPercent[name] = filterInput(value); //formatValue(value, dataType);
        }
      }
    }

    setFilter({ ...values });
    // setTriggerDelay(true);

    dispatch(setTableFilter({ ...values }));
  };
  const rangeFilter = (name, params = {}) => {
    const dataType = params?.dataType;

    const validateRange = (type) => {
      const values = { ...filter };
      let { percentileMin, percentileMax } = values.percentile;
      if (percentileMax === "") {
        values.percentile.percentileMax = 100;
      }
      if (percentileMin === "") {
        values.percentile.percentileMin = 0;
      }
      if (parseFloat(percentileMin) > parseFloat(percentileMax)) {
        if (type === "min") {
          values.percentile.percentileMax = 100;
        } else {
          values.percentile.percentileMin = 0;
        }
      }

      let { payRatioMin, payRatioMax } = values.payRatio;
      if (payRatioMax === "") {
        values.payRatio.payRatioMax = (1e5).toLocaleString();
      }
      if (payRatioMin === "") {
        values.payRatio.payRatioMin = 0;
      }
      if (parseFloat(payRatioMin) > parseFloat(payRatioMax)) {
        if (type === "min") {
          values.payRatio.payRatioMax = (1e5).toLocaleString();
        } else {
          values.payRatio.payRatioMin = 0;
        }
      }

      let { salaryMin, salaryMax } = values.salary;
      if (salaryMin > salaryMax) {
        salaryMin = salaryMin ^ salaryMax;
        salaryMax = salaryMin ^ salaryMax;
        salaryMin = salaryMin ^ salaryMax;
        values.salary = {
          salaryMin,
          salaryMax,
        };
      }

      setFilter({ ...values });
      dispatch(setTableFilter({ ...values }));
    };

    const handleBlur = () => {
      if (name === "percentile") {
        validateRange("min");
      }
      if (name === "executiveRank") {
        validateRange("min");
      }
      if (name === "payRatio") {
        validateRange("min");
      }
      if (
        name === "companyRank" &&
        (filter.companyRank.companyRankMin === "" ||
          filter.companyRank.companyRankMax === "")
      ) {
        let values = {
          ...filter,
          companyRank: {
            companyRankMin: filter.companyRank.companyRankMin || null,
            companyRankMax: filter.companyRank.companyRankMax || null,
          },
        };
        dispatch(setTableFilter({ ...values }));
        // setTriggerUpdate(true);
      }
    };

    let showCompactRangeFilter = true;

    const minInput = () => (
      <>
        <Input
          min=""
          max={name === "percentile" ? "100" : ""}
          name={`${name}Min`}
          value={
            filter?.[`${name}`]?.[`${name}Min`] == null
              ? params?.placeholderMin
              : filter?.[`${name}`]?.[`${name}Min`]
          }
          type={name === "percentile" ? "number" : "text"}
          placeholder={
            params?.placeholderMin !== undefined
              ? `${params?.placeholderMin?.toLocaleString()}${
                  params?.canGoBeyondMin ? "+" : ""
                }`
              : ""
          }
          onChange={(e) => handleChangeRange(e, dataType)}
          onBlur={handleBlur}
          style={{ width: 250, marginLeft: 5 }}
        />
        {name === "payRatio" && <span> : 1</span>}
      </>
    );
    const maxInput = () => (
      <>
        <Input
          min=""
          max={name === "percentile" ? "100" : ""}
          name={`${name}Max`}
          value={
            filter?.[`${name}`]?.[`${name}Max`] == null
              ? params?.placeholderMax
              : filter?.[`${name}`]?.[`${name}Max`]
          }
          type={name === "percentile" ? "number" : "text"}
          placeholder={
            params?.placeholderMax !== undefined
              ? `${params?.placeholderMax?.toLocaleString()}${
                  params?.canGoBeyondMax ? "+" : ""
                }`
              : ""
          }
          onChange={(e) => handleChangeRange(e, dataType)}
          onBlur={handleBlur}
          style={{ width: 250, marginLeft: 5 }}
        />
        {name === "payRatio" && (
          <span style={{ marginLeft: 0, paddingLeft: 0 }}> : 1</span>
        )}
      </>
    );
    if (showCompactRangeFilter) {
      return (
        //side-by-side:
        <div className="row">
          <div className="col-6 range">
            <label>From:&nbsp;&nbsp;</label>
            {minInput()}
          </div>
          <div className="col-6 range">
            <label>To:</label>
            {maxInput()}
          </div>
        </div>
      );
    } else {
      //stacked vertically:
      return (
        <div className="row">
          <div className="range">
            <label>From:</label>
            {minInput()}
          </div>
          <div className="range">
            <label>To:&nbsp;&nbsp;&nbsp;&nbsp;</label>
            {maxInput()}
          </div>
        </div>
      );
    }
  };
  const FilterHeading = ({ heading, measureKey, hoverText }) => {
    const measureHoverText =
      hoverText || measureOptions?.[measureKey]?.description;
    return (
      <div
        className="cohort-selector"
        style={{
          display: "flex",
          // alignItems: "start",
          // justifyContent: "start",
          // marginTop: 10,
        }}
      >
        {heading && (
          <>
            {measureHoverText ? (
              <Tooltip title={measureHoverText}>
                <div className="title">
                  <h3
                    style={{
                      fontSize: 20,
                      marginRight: 10,
                      marginBottom: 5,
                      marginTop: 5,
                      cursor: "help",
                    }}
                  >
                    {" "}
                    {heading.split(" ").map((head, index) => (
                      <span key={index}>{head}&nbsp;</span>
                    ))}
                  </h3>
                </div>
              </Tooltip>
            ) : (
              <div className="title">
                <h3
                  style={{
                    fontSize: 20,
                    marginRight: 10,
                    marginBottom: 5,
                    marginTop: 5,
                  }}
                >
                  {" "}
                  {heading.split(" ").map((head, index) => (
                    <span key={index}>{head}&nbsp;</span>
                  ))}
                </h3>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const newData = useMemo(() => {
    return new dataFilter(companiesValues, filter.currentYear, companies)
      .filterSector(filter.sector)
      .filterIndustry(filter.industry)
      .filterSIC(filter.SICs)
      .marketCapRange(
        filter.marketCap.marketCapMin,
        filter.marketCap.marketCapMax
      )
      .employeeRange(
        filter.employees.employeesMin,
        filter.employees.employeesMax
      )
      .netIncomeRange(
        filter.netIncome.netIncomeMin,
        filter.netIncome.netIncomeMax
      )
      .revenueRange(filter.revenue.revenueMin, filter.revenue.revenueMax)
      .assetsRange(filter.assets.assetsMin, filter.assets.assetsMax)
      .filterMeasure(filter.ROA.ROAMin, filter.ROA.ROAMax, "ROA")
      .filterMeasure(filter.ROE.ROEMin, filter.ROE.ROEMax, "ROE")
      .filterMeasure(
        filter.grossProfit.grossProfitMin,
        filter.grossProfit.grossProfitMax,
        "grossProfit"
      )
      .filterMeasure(
        filter.OCF_Assets.OCF_AssetsMin,
        filter.OCF_Assets.OCF_AssetsMax,
        "OCF_Assets"
      )
      .filterMeasure(
        filter.revenueGrowthPercent.revenueGrowthPercentMin,
        filter.revenueGrowthPercent.revenueGrowthPercentMax,
        "revenueGrowthPercent"
      )
      .companyRankRange(
        filter.companyRank.companyRankMin,
        filter.companyRank.companyRankMax,
        filteredMarketCap
      )
      .payRatioRange(filter.payRatio.payRatioMin, filter.payRatio.payRatioMax)
      .filterIndexes(filter.index).data;
  }, [companies, companiesValues, filter, filteredMarketCap]).map(
    (item) => item.Ticker
  );

  useMemo(() => {
    if (
      newData.length ===
        new dataFilter(companiesValues, filter.currentYear, companies).data
          .length &&
      JSON.stringify(filter) === JSON.stringify(initFilter)
    ) {
      setCustomCohortTickers((prev) => (prev.length !== 0 ? [] : prev));
      setNoActiveFilters(true);
    } else {
      setCustomCohortTickers((prev) =>
        prev.some((item) => !newData.includes(item)) ||
        newData.some((item) => !prev.includes(item))
          ? newData
          : prev
      );
      setNoActiveFilters(false);
    }
  }, [
    newData,
    setCustomCohortTickers,
    companiesValues,
    companies,
    setNoActiveFilters,
    filter,
  ]);

  const sectorChildren = [];
  for (let i = 0; i < Sector.length; i++) {
    sectorChildren.push(<Option key={Sector[i]}>{Sector[i]}</Option>);
  }

  const SICCodeDigitOptions = [
    { label: "Two Digit", value: "twoDigits" },
    { label: "Three Digit", value: "threeDigits" },
    { label: "Four Digit", value: "fourDigits" },
  ];
  const [SICCodeOptionsSelected, setSICCodeOptionsSelected] = useState(
    SICCodeDigitOptions.map((item) => item.value)
  );
  const onSICCodeOptionChange = (SICCodeOptionSelected) => {
    setSICCodeOptionsSelected(SICCodeOptionSelected);
  };
  const SICCodeOptions = useMemo(() => {
    if (!SICCodes) return {};
    const allSICCodes = {};
    for (const SICCodeOption of SICCodeOptionsSelected) {
      if (!filteredSICs?.[SICCodeOption]) continue;
      for (const [key, val] of Object.entries(filteredSICs[SICCodeOption])) {
        allSICCodes[key] = val;
      }
    }
    return allSICCodes;
  }, [SICCodeOptionsSelected, filteredSICs]);
  const SICCodeChildren = Object.entries(SICCodeOptions).map(
    ([SICCode, SICIndustryName]) => (
      <Option key={SICCode} value={`${SICCode}`}>
        {SICCode} - {SICIndustryName}
      </Option>
    )
  );

  const industryChildren = useMemo(() => {
    const sortClosestIndustry = (a, b) => {
      // Sort most relevent results first
      if (!!searchVal) {
        if (a && b) {
          return (
            b.toLowerCase().startsWith(searchVal.toLowerCase()) -
            a.toLowerCase().startsWith(searchVal.toLowerCase())
          );
        } else {
          return 0;
        }
      } else {
        if (a && b) {
          const nameA = a.toLowerCase();
          const nameB = b.toLowerCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }
      }
    };
    return filteredIndustries
      .sort((a, b) => sortClosestIndustry(a, b))
      .map((industry, index) => (
        <Option key={index} value={`${industry}`}>
          {industry}
        </Option>
      ));
  }, [filteredIndustries, searchVal]);

  const updateLocationFilterOptions = useCallback(
    (executiveData, locationType) => {
      const titleCase = (txt) =>
        txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();

      if (update) {
        locationType = filter.locationFilter + titleCase(locationType); // can be companyCity or executiveCity
        if (
          (companies[executiveData.Ticker]?.[
            locationType.replace("company", "")
          ] ||
            executiveData?.[locationType]) &&
          executiveData?.[locationType] !== "Other"
        ) {
          return (
            companies[executiveData.Ticker]?.[
              locationType.replace("company", "")
            ] || executiveData?.[locationType]
          );
        }
        return undefined;
      }
    },
    [companies, filter.locationFilter, update]
  );

  const updateFilterOptions = useCallback(
    (filterName) => {
      // if (!delay || update) {
      const newData =
        filterName === "companyRank"
          ? new dataFilter(
              companies,
              filter.currentYear,
              companies
            ).filterIndexes(filter.index)
          : new dataFilter(companiesValues, filter.currentYear, companies)
              // .filterCSuite(csuite)
              .salaryRange(filter.salary.salaryMin, filter.salary.salaryMax)
              .filterIndustry(filterName === "industry" ? [] : filter.industry)
              .filterSIC(filterName === "SIC" ? [] : filter.SIC)
              .filterSector(filter.sector)
              .filterCompany(filterName === "company" ? [] : filter.company)
              .filterCompanyLocation(
                filterName === "state" ? [] : filter.companyState
              )
              .filterExecutiveState(
                filterName === "state" ? [] : filter.executiveState
              )
              .filterCompanyCity(
                filterName === "city" ? [] : filter.companyCity
              )
              .filterExecutive(
                filterName === "executive" ? [] : filter.executives
              )
              .filterExecutiveCity(
                filterName === "city" ? [] : filter.executiveCity
              )
              .percentileCompensation(
                filter.percentile.percentileMin,
                filter.percentile.percentileMax
              )
              .marketCapRange(
                filter.marketCap.marketCapMin,
                filter.marketCap.marketCapMax
              )
              .employeeRange(
                filter.employees.employeesMin,
                filter.employees.employeesMax
              )
              .netIncomeRange(
                filter.netIncome.netIncomeMin,
                filter.netIncome.netIncomeMax
              )
              .revenueRange(
                filter.revenue.revenueMin,
                filter.revenue.revenueMax
              )
              .assetsRange(filter.assets.assetsMin, filter.assets.assetsMax)
              .filterMeasure(filter.ROA.ROAMin, filter.ROA.ROAMax, "ROA")
              .filterMeasure(filter.ROE.ROEMin, filter.ROE.ROEMax, "ROE")
              .filterMeasure(
                filter.grossProfit.grossProfitMin,
                filter.grossProfit.grossProfitMax,
                "grossProfit"
              )
              .filterMeasure(
                filter.OCF_Assets.OCF_AssetsMin,
                filter.OCF_Assets.OCF_AssetsMax,
                "OCF_Assets"
              )
              .filterMeasure(
                filter.revenueGrowthPercent.revenueGrowthPercentMin,
                filter.revenueGrowthPercent.revenueGrowthPercentMax,
                "revenueGrowthPercent"
              )
              .companyRankRange(
                filter.companyRank.companyRankMin,
                filter.companyRank.companyRankMax,
                filteredMarketCap
              )
              .filterIndexes(filter.index)
              .filterFounders(filter.founders);
      const filterOptions = [];
      const filteredData = [...newData.data];
      for (const executiveData of filteredData) {
        switch (filterName) {
          case "company":
            if (
              executiveData?.Ticker &&
              !filterOptions.includes(executiveData.Ticker)
            ) {
              filterOptions.push(executiveData.Ticker);
            }
            break;
          case "cohort":
            if (
              executiveData?.Ticker &&
              !filterOptions.includes(executiveData.Ticker)
            ) {
              filterOptions.push(executiveData.Ticker);
            }
            break;
          case "proxyCohort":
            if (
              executiveData?.Ticker &&
              !filterOptions.includes(executiveData.Ticker)
            ) {
              filterOptions.push(executiveData.Ticker);
            }
            break;
          case "companyRank":
            if (
              executiveData?.Ticker &&
              !filterOptions.includes(executiveData.Ticker)
            ) {
              filterOptions.push(executiveData.Ticker);
            }
            break;
          case "state":
            const possibleNewState = updateLocationFilterOptions(
              executiveData,
              "state"
            );
            if (possibleNewState && !filterOptions.includes(possibleNewState)) {
              filterOptions.push(possibleNewState);
            }
            break;
          case "city":
            const possibleNewCity = updateLocationFilterOptions(
              executiveData,
              "city"
            );
            if (possibleNewCity && !filterOptions.includes(possibleNewCity)) {
              filterOptions.push(possibleNewCity.toLowerCase());
            }
            break;
          case "industry":
            if (
              executiveData?.industry &&
              !filterOptions.includes(executiveData.industry)
            ) {
              filterOptions.push(executiveData.industry);
            }
            break;
          case "SIC":
            if (
              companies?.[executiveData?.Ticker]?.SIC &&
              !filterOptions.includes(companies?.[executiveData?.Ticker]?.SIC)
            ) {
              filterOptions.push(companies?.[executiveData?.Ticker]?.SIC);
            }
            break;
          case "executive":
            if (
              executiveData?.name &&
              !filterOptions.includes(executiveData.name)
            ) {
              filterOptions.push(executiveData);
            }
            break;
          default:
            console.log("Filter Error: No filter type provided.");
        }
      }
      return filterOptions;
      // }
    },
    [
      companies,
      companiesValues,
      filteredMarketCap,
      filter,
      updateLocationFilterOptions,
    ]
  );

  useEffect(() => {
    const version = localStorage.getItem("version") || "1.0.0";
    async function fetchLocal(path) {
      const response = await fetch(path);
      const result = await response.json();
      return result;
    }
    async function getIndexes() {
      const DowData = await fetchLocal(`/data/DowTickers.json?ver=${version}`);
      const RussellData = await fetchLocal(
        `/data/Russell3000Tickers.json?ver=${version}`
      );
      const SP500Data = await fetchLocal(
        `/data/SP500Tickers.json?ver={version}`
      );
      setIndexTickers({ DowData, RussellData, SP500Data });
    }
    getIndexes();
  }, []);
  const handleIndexChange = (e) => {
    let values = { ...filter, indexSelected: e.target.value };
    let currentIndexTickers = [];
    switch (values.indexSelected) {
      case "Dow Jones":
        currentIndexTickers = indexTickers.DowData[filter.currentYear].Tickers;
        break;
      case "S&P 500":
        currentIndexTickers =
          indexTickers.SP500Data[filter.currentYear].Tickers;
        break;
      case "Russell 3000":
        currentIndexTickers =
          indexTickers.RussellData[filter.currentYear].Tickers;
        break;
      default:
        break;
    }
    values = { ...values, index: currentIndexTickers };
    //reset company rank too, max values will be different for each index:
    values = {
      ...values,
      companyRank: {
        companyRankMin: null,
        companyRankMax: null,
      },
    };
    dispatch(setTableFilter({ ...values }));
    // setTriggerUpdate(true);
  };

  const handleYearChange = (e) => {
    let values = { ...filter, currentYear: e.target.value };
    dispatch(setTableFilter({ ...values }));
    // setTriggerUpdate(true);
  };

  useMemo(() => {
    if (update) {
      setNewValidTickers(updateFilterOptions("company"));
      setNewValidCohorts(updateFilterOptions("cohort"));
      setNewValidStates(updateFilterOptions("state"));
      setNewValidCities(updateFilterOptions("city"));
      setFilteredIndustries(updateFilterOptions("industry"));
      setFilteredSICs(updateFilterOptions("SIC"));
      setCompanyRankCompanies(updateFilterOptions("companyRank"));
    }
  }, [update, updateFilterOptions]);

  const yearsAvailable = useMemo(() => {
    const yearsAvailable = [];
    for (let yr = new Date().getFullYear() - 1; yr >= MIN_YEAR; yr--) {
      yearsAvailable.push(yr);
    }
    return yearsAvailable;
  }, []);

  return (
    <div>
      <div
        className="peerGroupTableFiler table-filter"
        style={{ outline: "1px solid black", margin: 10, padding: 10 }}
      >
        <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
          Create Peer Group With Metrics
        </h4>
        <div className="year filter geography" style={{ display: "flex" }}>
          <FilterHeading
            heading="Year:"
            hoverText={`Year for all Peer Group Filters 
            (e.g., if ${DEFAULT_YEAR} is selected, revenue will be filtered based on the ${DEFAULT_YEAR} value).`}
          />
          <select
            name="year"
            onChange={handleYearChange}
            value={filter?.currentYear || DEFAULT_YEAR}
            style={{ width: 75, textAlign: "center" }}
          >
            {yearsAvailable.map((yr, index) => (
              <option value={parseInt(yr)} key={index}>
                {yr}
              </option>
            ))}
          </select>
        </div>
        <FilterComponent
          heading="Sector:"
          children={sectorChildren}
          filterKey="sector"
          hoverText="Filter companies based on Sector name."
        />
        <FilterComponent
          heading="Industry:"
          children={industryChildren}
          filterKey="industry"
          hoverText="Filter companies based on Industry name."
        />
        <FilterComponent
          heading="SIC Codes:"
          children={SICCodeChildren}
          filterKey="SICs"
          hoverText={
            <div>
              Filter companies based on SIC code. Use the SIC code option
              checkboxes to explore specific options:
              <ul style={{ marginBottom: 0 }}>
                <li>2 Digits: Major Group</li>
                <li>3 Digits: Industry Group</li>
                <li>4 Digits: Industry</li>
              </ul>
            </div>
          }
        />
        <span style={{ paddingLeft: 108, paddingRight: 5 }}>
          Show SIC Code Options:
        </span>
        <Checkbox.Group
          options={SICCodeDigitOptions}
          // defaultValue={["twoDigit", "threeDigit", "fourDigit"]}
          onChange={onSICCodeOptionChange}
          value={SICCodeOptionsSelected}
        />
        <FilterHeading
          heading="Current Employee Count:"
          measureKey="employees"
          hoverText="Filter based on the current employee count for the company"
        />
        {rangeFilter("employees", {
          placeholderMin: 0,
          placeholderMax: 1e6,
          canGoBeyondMax: true,
        })}
        <FilterHeading
          heading="Current Market Cap ($):"
          measureKey="marketCap"
          hoverText="Filter based on the current market cap for the company"
        />
        {rangeFilter("marketCap", {
          placeholderMin: 0,
          placeholderMax: 5e12,
          canGoBeyondMax: true,
        })}
        <FilterHeading
          heading={`${filter.currentYear} Assets ($):`}
          measureKey="assets"
        />
        {rangeFilter("assets", {
          placeholderMin: 0,
          placeholderMax: 5e11,
          canGoBeyondMax: true,
        })}
        <FilterHeading
          heading={`${filter.currentYear} Revenue ($):`}
          measureKey="revenue"
        />
        {rangeFilter("revenue", {
          placeholderMin: 0,
          placeholderMax: 5e12,
          canGoBeyondMax: true,
        })}
        <FilterHeading
          heading={`${filter.currentYear} Net Income ($):`}
          measureKey="netIncome"
        />
        {rangeFilter("netIncome", {
          placeholderMin: -1e12,
          placeholderMax: 1e12,
          canGoBeyondMin: true,
          canGoBeyondMax: true,
        })}
        <FilterHeading
          heading={`${filter.currentYear} Return on Assets (%):`}
          measureKey="ROA"
        />
        {rangeFilter("ROA", {
          placeholderMin: -1e6,
          placeholderMax: 1e9,
          canGoBeyondMin: true,
          canGoBeyondMax: true,
        })}
        <FilterHeading
          heading={`${filter.currentYear} Return on Equity (%):`}
          measureKey="ROE"
        />
        {rangeFilter("ROE", {
          placeholderMin: -1e6,
          placeholderMax: 1e6,
          canGoBeyondMin: true,
          canGoBeyondMax: true,
        })}
        <FilterHeading
          heading={`${filter.currentYear} Gross Profit ($):`}
          measureKey="grossProfit"
        />
        {rangeFilter("grossProfit", {
          placeholderMin: -1e12,
          placeholderMax: 1e12,
          canGoBeyondMin: true,
          canGoBeyondMax: true,
        })}
        <FilterHeading
          heading={`${filter.currentYear} Revenue Growth Percent (%):`}
          measureKey="revenueGrowthPercent"
        />
        {rangeFilter("revenueGrowthPercent", {
          dataType: "percent",
          placeholderMin: -1e12,
          placeholderMax: 1e12,
          canGoBeyondMin: true,
          canGoBeyondMax: true,
        })}
        <FilterHeading
          heading={`${filter.currentYear} OCF to Assets Ratio:`}
          measureKey="OCF_Assets"
        />
        {rangeFilter("OCF_Assets", {
          dataType: "ratio",
          placeholderMin: -1.5,
          placeholderMax: 1.5,
          canGoBeyondMin: true,
          canGoBeyondMax: true,
        })}
        <FilterHeading
          heading={`${filter.currentYear} CEO pay ratio:`}
          measureKey="payRatio"
        />
        {rangeFilter("payRatio")}
        <div
          className="table-filter borderBox"
          style={{ marginTop: 10, paddingTop: 10 }}
        >
          <div className="year filter geography" style={{ display: "flex" }}>
            <FilterHeading
              heading={`Index (${filter.currentYear}):`}
              hoverText="Filter companies based on Index (e.g., S&P 500 or Russell 3000). This filter will affect the company rank (each company will be ranked against it's index members based on current market cap value). Index composition is determined based on June of year selected."
            />
            <select
              name="index"
              onChange={handleIndexChange}
              value={filter.indexSelected}
              style={{ width: "50%", textAlign: "center" }}
            >
              <option value={"All"} key={0}>
                All
              </option>
              <option value={"Dow Jones"} key={1}>
                Dow Jones
              </option>
              <option value={"S&P 500"} key={2}>
                S&amp;P 500
              </option>
              <option value={"Russell 3000"} key={3}>
                Russell 3000
              </option>
            </select>
          </div>
          <FilterHeading
            heading="Current Company Rank:"
            hoverText="Filter companies based on their rank (based on current market cap value). Note: this ranking will be affected by index selected above."
          />
          {rangeFilter("companyRank", {
            placeholderMin: 1,
            placeholderMax:
              filter.indexSelected === "All"
                ? Object.values(companies).length?.toLocaleString()
                : filter.index.length?.toLocaleString(), //?.[filter.currentYear].Tickers.length?.toLocaleString(),
          })}
        </div>
        <div className="table-filter">
          <div className="reset text-center my-3">
            <button
              onClick={() => {
                dispatch(resetTableFilter());
                // dispatch(setTableFilter(initFilter));
                // setTriggerUpdate(true);
                setFilter(initFilter);
              }}
            >
              Reset Filters
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
