import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table, Radio, Switch } from "antd";
import PuffLoader from "react-spinners/PuffLoader";
import AwardsTable from "./subcomponents/AwardsTable";
import {
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Bar,
  Cell,
  Tooltip,
  ResponsiveContainer,
  Label,
} from "recharts";

import {
  runHorseRace,
  runOptimisticHorseRace,
  clearHorseRaceResults,
} from "../../redux/actions/CompanyAction";

import {
  getPercentileFromValue,
  hasHoverText,
} from "../../utils/Constants/systemSetting";

import CohortSelector from "../ExecutiveOverview/CohortSection/CohortSelector";
import CustomInput from "./utils/CustomInput";
import AwardsResults from "./subcomponents/AwardsResults";
import { OptionsFAQTopics } from "../../assets/faqTopicsOptions";
import CustomTitleElement from "./utils/CustomTitleElement";

const reviseTickerMap = {
  GOOG: "GOOGL",
  PARAA: "PARA",
  LBRDK: "LBRDA",
};

export default function SimulatePeerRankings({ companyInfo }) {
  const dispatch = useDispatch();

  const [cohortTableSelector, setCohortTableSelector] = useState("");
  const [cohortTableTickers, setCohortTableTickers] = useState([]);

  const [optimisticLoading, setOptimisticLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const { horseRaceResults, optimisticHorseRaceResults } = useSelector(
    (state) => state.CompanyReducer
  );

  const [startOptimisticHorseRace, setStartOptimisticHorseRace] =
    useState(false);
  const [startHorseRace, setStartHorseRace] = useState(false);

  const [numberOfTrialsCompleted, setNumberOfTrialsCompleted] = useState(0);
  const [numberOfTrialsCompletedFullRun, setNumberOfTrialsCompletedFullRun] =
    useState(0);

  useEffect(() => {
    dispatch(clearHorseRaceResults());
  }, []);

  const [performanceModeSelected, setPerformanceModeSelected] =
    useState("High Speed");
  const [negativePayoutStructure, setNegativePayoutStructure] = useState(false);
  const [showAllResults, setShowAllResults] = useState(false);

  const optimisticNumberOfTrials =
    performanceModeSelected === "High Speed" ? 100 : 1000;

  const [peerRankingParams, setPeerRankingParams] = useState({
    numberOfTrials: {
      label: "Number of Trials",
      value: "1,000",
      min: 1,
      max: 1e6,
      hoverText: "",
    },
    numberOfWeeks: {
      label: "Number of Weeks",
      value: 52,
      min: 1,
      max: 520,
      hoverText: "",
    },
    drift: {
      label: "Annual Drift (%)",
      value: companyInfo?.Drift || 12,
      min: 0,
      max: 100,
      hoverText: OptionsFAQTopics.annual_drift.short_desc,
      isDecimal: true,
    },
    stockPrice: {
      label: "Stock Price (Unadjusted) on Start Date ($)",
      value: 20,
      min: 0,
      max: 1e6,
      hoverText: "",
      isDecimal: true,
    },
    numberOfShares: {
      label: "Number of Shares Awarded (#)",
      value: "50,000",
      min: 0,
      max: 1e6,
      hoverText: "",
    },
  });

  useMemo(() => {
    if (performanceModeSelected === "High Precision") {
      setPeerRankingParams((prev) => {
        prev.numberOfTrials.value = "10,000";
        return prev;
      });
    }
    if (performanceModeSelected === "High Speed") {
      setPeerRankingParams((prev) => {
        prev.numberOfTrials.value = "1,000";
        return prev;
      });
    }
  }, [performanceModeSelected]);

  useMemo(() => {
    if (
      negativePayoutStructure &&
      !Object.keys(peerRankingParams).includes("negitivePayoutMaximum")
    ) {
      setPeerRankingParams((prev) => ({
        ...prev,
        negitivePayoutMaximum: {
          label: "Maximum Number of Shares if Return is Negative (#)",
          value: "50,000",
          min: 0,
          max: 1e6,
          hoverText: "",
        },
      }));
    }
    if (
      !negativePayoutStructure &&
      Object.keys(peerRankingParams).includes("negitivePayoutMaximum")
    ) {
      setPeerRankingParams((prev) => {
        delete prev.negitivePayoutMaximum;
        return prev;
      });
    }
  }, [negativePayoutStructure, peerRankingParams]);

  const revisedCohortTableTickers = useMemo(() => {
    const revisedCohortTableTickers = cohortTableTickers.map((item) => {
      if (Object.keys(reviseTickerMap).includes(item)) {
        return reviseTickerMap[item];
      }
      return item;
    });
    const revisedCohortTableTickersWithoutCompanyPageCompany =
      revisedCohortTableTickers.filter((item) => item !== companyInfo.Ticker);
    const revisedCohortTableTickersWithCompanyTickerFirst = [
      companyInfo.Ticker,
      ...revisedCohortTableTickersWithoutCompanyPageCompany,
    ];
    return revisedCohortTableTickersWithCompanyTickerFirst;
  }, [cohortTableTickers, companyInfo.Ticker]);

  const [waitingForClearedResults, setWaitingForClearedResults] =
    useState(false);

  useEffect(() => {
    const token = localStorage.getItem("ACCESS_TOKEN");

    if (revisedCohortTableTickers?.length) {
      const searchParams = {
        peer_group: Array(...revisedCohortTableTickers),
        n_trials: parseInt(
          peerRankingParams.numberOfTrials.value.toString().replaceAll(",", "")
        ),
        weeks: parseInt(peerRankingParams.numberOfWeeks.value),
        // drift: peerRankingParams.drift.value / Math.sqrt(52) / 100,
        drift: Math.pow(1 + peerRankingParams.drift.value / 100, 1 / 52) - 1,
      };

      if (startOptimisticHorseRace) {
        dispatch(clearHorseRaceResults());
        setWaitingForClearedResults(true);
        setTableData([]);
        setOptimisticTableData([]);
      }

      if (
        waitingForClearedResults &&
        !optimisticHorseRaceResults?.data &&
        !horseRaceResults?.data
      ) {
        // console.log("Running optimistic race now");
        setWaitingForClearedResults(false);
        setOptimisticLoading(true);
        setStartOptimisticHorseRace(false);
        setNumberOfTrialsCompletedFullRun(0);

        dispatch(
          runOptimisticHorseRace({
            searchParams: {
              ...searchParams,
              n_trials: optimisticNumberOfTrials,
            },
            token,
          })
        );
        setStartOptimisticHorseRace(false);
      }

      if (startHorseRace && !loading) {
        // console.log("Running actual race now");
        setLoading(true);
        dispatch(runHorseRace({ searchParams, token }));
        setStartHorseRace(false);
      }
    }
  }, [
    dispatch,
    companyInfo,
    revisedCohortTableTickers,
    peerRankingParams,
    loading,
    startHorseRace,
    startOptimisticHorseRace,
    optimisticLoading,
    numberOfTrialsCompletedFullRun,
    horseRaceResults,
    optimisticHorseRaceResults,
    waitingForClearedResults,
    optimisticNumberOfTrials,
  ]);

  const [tableColumns, setTableColumns] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [tickersWithDataLength, setTickersWithDataLength] = useState(1);

  const [optimisticTableColumns, setOptimisticTableColumns] = useState([]);
  const [optimisticTableData, setOptimisticTableData] = useState([]);

  useMemo(() => {
    if (!horseRaceResults || !loading) return;

    const tickersWithData = horseRaceResults?.data?.tickers;

    const allRankingData = horseRaceResults?.data?.rankingTable;
    const allPositiveReturnData = horseRaceResults?.data?.postiveReturnTable;

    if (
      horseRaceResults &&
      (tickersWithData?.length >= 0 || horseRaceResults?.data)
    ) {
      setLoading(false);
      setTableColumns([]);
      setTableData([]);
      setOptimisticLoading(false);
      setOptimisticTableColumns([]);
      setOptimisticTableData([]);
      setNumberOfTrialsCompletedFullRun(peerRankingParams.numberOfTrials.value);

      if (tickersWithData?.length >= 1) {
        const newTableColumnData = [
          {
            title: "Rank",
            dataIndex: "rank",
            key: "rank",
          },
          {
            title: "Percentile",
            dataIndex: "percentile",
            key: "percentile",
          },
        ];
        tickersWithData.forEach((ticker, index) =>
          newTableColumnData.push({
            title: (
              <span
                style={{
                  fontWeight: ticker === companyInfo.Ticker ? "bold" : "",
                }}
              >
                {ticker}
              </span>
            ),
            dataIndex: ticker,
            key: index,

            render: (cellData, rowData) => {
              return (
                <span
                  style={{
                    fontWeight: ticker === companyInfo.Ticker ? "bold" : "",
                  }}
                >
                  {cellData}
                </span>
              );
            },
          })
        );
        setTableColumns(newTableColumnData);

        const allRanks = [];
        for (let i = 0; i < allRankingData.length; i++) {
          allRanks.push(i + 1);
        }

        const newTableData = allRankingData.map(
          (dataForRank, indexOfRankData) => {
            const ranking = indexOfRankData + 1;

            // Needs to be reversed, 4 = lowest usually percentile calculates the other way
            const totalEntries = allRanks.length;
            const percentileRanking = totalEntries - indexOfRankData;

            const tableDataForRank = {
              key: ranking,
              rank: ranking,
              percentile: parseFloat(
                getPercentileFromValue(allRanks, percentileRanking)
              ).toFixed(2),
            };
            dataForRank.forEach(
              (rankDataPoint, rankDataPointIndex) =>
                (tableDataForRank[tickersWithData[rankDataPointIndex]] =
                  rankDataPoint)
            );

            allPositiveReturnData[indexOfRankData].forEach(
              (rankDataPoint, rankDataPointIndex) => {
                if (
                  tickersWithData[rankDataPointIndex] === companyInfo.Ticker
                ) {
                  tableDataForRank[`positiveReturn`] = rankDataPoint;
                }
              }
            );

            return tableDataForRank;
          }
        );
        setTableData(newTableData);
      }
    }
  }, [
    horseRaceResults,
    companyInfo,
    peerRankingParams.numberOfTrials.value,
    loading,
  ]);

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  const slowedForLoop = useCallback(
    async (newTableData, allResults) => {
      let updateEvery = 1;
      const updatesNeeded = Math.ceil(allResults.length / updateEvery);

      for (let update = 1; update <= updatesNeeded; update++) {
        const totalDuration =
          1000 *
            3.96739e-5 *
            (revisedCohortTableTickers.length / 23) *
            peerRankingParams.numberOfTrials.value +
          6.032e-1;
        const timeoutDuration =
          update < (performanceModeSelected === "High Speed" ? 5 : 10)
            ? 250
            : (totalDuration * (updatesNeeded - update)) /
                (updatesNeeded * (updatesNeeded + 1)) /
                performanceModeSelected ===
              "High Speed"
            ? 100000
            : 1;

        if (numberOfTrialsCompletedFullRun !== 0) {
          return;
        }

        setNumberOfTrialsCompleted(updateEvery * update);
        await timeout(timeoutDuration);
        const startIdx = (update - 1) * updateEvery;
        const endIdx = update * updateEvery;
        const currentBatch = allResults.slice(startIdx, endIdx);

        const updatedTableData = (prevTableData) => {
          const newTableDataCopy = [...prevTableData];

          for (const companyOfInterestResult of currentBatch) {
            newTableDataCopy[companyOfInterestResult.Rank - 1][
              companyInfo.Ticker
            ] += 1;
          }

          // To returns of increasing count:
          return newTableDataCopy;
        };

        setOptimisticTableData(updatedTableData(newTableData));
      }
    },
    [
      companyInfo,
      peerRankingParams.numberOfTrials,
      revisedCohortTableTickers,
      numberOfTrialsCompletedFullRun,
      performanceModeSelected,
    ]
  );

  const [optimisticTableDataAsPercent, setOptimisticTableDataAsPercent] =
    useState([]);
  useEffect(() => {
    if (!optimisticTableData) return [];

    const optimisticTableDataCopy = optimisticTableData.map((row) => ({
      ...row,
    }));
    const totalIterations = optimisticTableDataCopy.reduce(
      (acc, row) => acc + (row[companyInfo.Ticker] || 0),
      0
    );

    const optimisticTableDataAsPercent = optimisticTableDataCopy.map((item) => {
      item[`${companyInfo.Ticker}`] =
        item[companyInfo.Ticker] / totalIterations;
      return item;
    });
    setOptimisticTableDataAsPercent(optimisticTableDataAsPercent);
  }, [optimisticTableData, companyInfo]);

  useMemo(async () => {
    if (!optimisticHorseRaceResults || !optimisticLoading) return;

    const tickersWithData = optimisticHorseRaceResults?.data?.tickers;
    const allRankingData = optimisticHorseRaceResults?.data?.rankingTable;
    const allPositiveReturnData =
      optimisticHorseRaceResults?.data?.postiveReturnTable;

    const allResultsString = optimisticHorseRaceResults?.data?.allResults;

    if (
      optimisticLoading &&
      optimisticHorseRaceResults &&
      (tickersWithData?.length >= 0 || optimisticHorseRaceResults?.data)
    ) {
      setStartHorseRace(true);
      setOptimisticLoading(false);
      setOptimisticTableColumns([]);
      setOptimisticTableData([]);
      let allResults = [];
      if (allResultsString?.length > 0 && !allResults.length) {
        allResults = JSON.parse(allResultsString);
      }

      setTickersWithDataLength(tickersWithData?.length);

      if (tickersWithData?.length >= 1) {
        const newTableColumnData = [
          {
            title: "Rank",
            dataIndex: "rank",
            key: "rank",
          },
          {
            title: "Percentile",
            dataIndex: "percentile",
            key: "percentile",
          },
        ];
        allResults.forEach((rankData, index) =>
          newTableColumnData.push({
            title: companyInfo.Ticker,
            dataIndex: companyInfo.Ticker,
            key: index,
          })
        );
        // setOptimisticTableColumns(newTableColumnData);

        const newTableData = allRankingData.map(
          (dataForRank, indexOfRankData) => {
            const ranking = indexOfRankData + 1;
            const tableDataForRank = { key: ranking, rank: ranking };
            tableDataForRank[companyInfo.Ticker] = 0;
            return tableDataForRank;
          }
        );

        if (!horseRaceResults?.data) {
          await slowedForLoop(newTableData, allResults);
        }

        return;
      }
    }
  }, [
    optimisticHorseRaceResults,
    companyInfo,
    optimisticLoading,
    slowedForLoop,
    horseRaceResults,
  ]);

  const filterInput = (input, min, max, isDecimal = false) => {
    let newInput = input
      .toString()
      .replace(/[^0-9.]/g, "")
      .slice(0, 15);
    if (!isDecimal) {
      newInput = newInput.replace(/[.]/g, "");
    }
    if (newInput.split(".").length > 2) {
      newInput = newInput.split(".").slice(0, 2).join(".");
    }
    if (newInput === "") return "";
    if (newInput >= max) newInput = max;
    if (newInput < min) newInput = min;

    // Format string nicely (e.g. 1234.5678 -> 1,234.5678)
    if (!isDecimal) {
      newInput = parseFloat(newInput).toLocaleString();
    }

    if (isDecimal) {
      if (newInput === ".") {
        return "0.";
      }
      if (
        newInput.toString().split(".").length > 1 &&
        newInput.toString().split(".")[1].length > 0
      ) {
        newInput = parseFloat(newInput).toLocaleString(undefined, {
          minimumFractionDigits: 1,
          maximumFractionDigits: 4,
        });
      } else if (!newInput.toString().includes(".")) {
        newInput = parseFloat(newInput).toLocaleString();
      } else {
        newInput = parseFloat(newInput).toLocaleString() + ".";
      }
    }

    return newInput;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{ backgroundColor: "white", padding: 10, outline: "none" }}
        >
          <p className="label">{`Rank ${label}: ${parseFloat(
            payload[0].value * 100
          ).toFixed(1)}% Chance`}</p>
        </div>
      );
    }
    return null;
  };

  const [allAwardsValues, setAllAwardsValues] = useState([]);
  const [totalAwardValue, setTotalAwardValue] = useState(0);

  // Functions for coloring the bar chart:
  function getColorByPercentile(value, values) {
    // Sort the array in ascending order
    const sortedValues = [...values].sort((a, b) => a - b);

    // Find the rank of the value (its position in the sorted array)
    const rank = sortedValues.indexOf(value);

    // Normalize the rank to a 0-1 scale
    const normalizedRank = rank / (sortedValues.length - 1);

    // const colors = {
    //   green: { color1: "#38812F", color2: "#7CC674" },
    //   blue: { color1: "#519DE9", color2: "#004B95" },
    //   cyan: { color1: "#009596", color2: "#73C5C5" },
    //   purple: { color1: "#3C3D99", color2: "#5752D1" },
    //   gold: { color1: "#F0AB00", color2: "#F4C145" },
    //   orange: { color1: "#C46100", color2: "#EC7A08" },
    //   red: { color1: "#470000", color2: "#7D1007" },
    //   black: { color1: "#8A8D90", color2: "#B8BBBE" },
    // };

    const colors = {
      green: { color1: "#009596", color2: "#7CC674" },
      blue: { color1: "#004B95", color2: "#009596" },
      purple: { color1: "#8481DD", color2: "#3C3D99" },
      orange: { color1: "#C9190B", color2: "#8481DD" },
      red: { color1: "#470000", color2: "#A30000" },
    };

    const thresholds = [0, 0.2, 0.4, 0.6, 0.8, 1.0];

    const determineColor = (normalizedRank) => {
      // Determine the percentile range
      if (normalizedRank <= thresholds[1]) {
        // Top 20%
        return {
          colorName: "red",
          thresholdStart: thresholds[0],
          thresholdEnd: thresholds[1],
        };
      } else if (normalizedRank <= thresholds[2]) {
        // Next 40%
        return {
          colorName: "orange",
          thresholdStart: thresholds[1],
          thresholdEnd: thresholds[2],
        };
      } else if (normalizedRank <= thresholds[3]) {
        // Next 40%
        return {
          colorName: "purple",
          thresholdStart: thresholds[2],
          thresholdEnd: thresholds[3],
        };
      } else if (normalizedRank <= thresholds[4]) {
        // Next 20%
        return {
          colorName: "blue",
          thresholdStart: thresholds[3],
          thresholdEnd: thresholds[4],
        };
      } else {
        // Bottom 20%
        return {
          colorName: "green",
          thresholdStart: thresholds[4],
          thresholdEnd: thresholds[5],
        };
      }
    };
    const colorAndThreshold = determineColor(normalizedRank);
    const colorValues = colors[colorAndThreshold.colorName];

    const adjustedRank =
      (normalizedRank - colorAndThreshold.thresholdStart) /
      (colorAndThreshold.thresholdEnd - colorAndThreshold.thresholdStart);

    return interpolateColor(
      adjustedRank,
      colorValues.color1,
      colorValues.color2
    );
  }

  function interpolateColor(value, color1, color2) {
    // color1 = "#519DE9";
    // color2 = "#EC7A08";

    // Ensure value is within the range 0-1
    value = Math.min(Math.max(value, 0), 1);

    // Convert hex colors to RGB
    const c1 = hexToRgb(color1);
    const c2 = hexToRgb(color2);

    // Interpolate each RGB component
    const r = Math.round(c1.r + value * (c2.r - c1.r));
    const g = Math.round(c1.g + value * (c2.g - c1.g));
    const b = Math.round(c1.b + value * (c2.b - c1.b));

    // Convert the interpolated RGB back to hex
    return rgbToHex(r, g, b);
  }

  function hexToRgb(hex) {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, "");

    let bigint;
    if (hex.length === 3) {
      // Handle shorthand hex color codes (#RGB)
      bigint = parseInt(
        hex
          .split("")
          .map((c) => c + c)
          .join(""),
        16
      );
    } else {
      // Handle full hex color codes (#RRGGBB)
      bigint = parseInt(hex, 16);
    }

    return {
      r: (bigint >> 16) & 255,
      g: (bigint >> 8) & 255,
      b: bigint & 255,
    };
  }

  function rgbToHex(r, g, b) {
    return (
      "#" +
      ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
    );
  }
  // End of functions for coloring the bar chart

  return (
    <div>
      <div className="user-options" style={{ margin: 15 }}>
        <div
          style={{
            width: "100%",
            display: "flex",
          }}
        >
          <CohortSelector
            companyInfo={companyInfo}
            setCohortTableTickers={setCohortTableTickers}
            cohortTableSelector={cohortTableSelector}
            setCohortTableSelector={setCohortTableSelector}
            heading="Comparison Group:"
            removeCustomCohort={true}
            onlyIncludePeerGroups={true}
          />
        </div>
        {cohortTableTickers?.length >= 1 && (
          <div style={{ marginTop: 5 }}>
            <p>
              This Comparison Group Includes:{" "}
              {cohortTableTickers
                .sort((a, b) => (a > b ? 1 : b > a ? -1 : 0))
                .join(", ")}
            </p>
            <p style={{ marginTop: 10 }}>
              You can adjust this Comparison Group by creating a Custom peer
              group in the <b>Peer Group Analysis</b> Module
            </p>
          </div>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            margin: 15,
          }}
        >
          <CustomTitleElement
            heading="Select Performance Mode:"
            hoverText={OptionsFAQTopics.select_performance_mode.short_desc}
            element={
              <Radio.Group
                defaultValue="High Speed"
                value={performanceModeSelected}
                onChange={(e) => {
                  dispatch(clearHorseRaceResults());
                  setTableData([]);
                  setOptimisticTableData([]);
                  setPerformanceModeSelected(e.target.value);
                }}
                buttonStyle="solid"
              >
                <Radio.Button value="High Speed">High Speed</Radio.Button>
                <Radio.Button value="High Precision">
                  High Precision
                </Radio.Button>
              </Radio.Group>
            }
          />
        </div>

        {Object.entries(peerRankingParams).map(
          ([parameterKey, parameterValues]) => (
            <div key={parameterKey}>
              <CustomInput
                state={parameterValues.value}
                setState={(val) =>
                  setPeerRankingParams((prev) => {
                    val = filterInput(
                      val,
                      parameterValues.min,
                      parameterValues.max,
                      parameterValues.isDecimal
                    );
                    const newParams = { ...prev };
                    const prevValue = newParams[parameterKey];
                    newParams[parameterKey] = { ...prevValue, value: val };
                    return newParams;
                  })
                }
                heading={parameterValues.label + ":"}
                style={{ margin: 15 }}
                key={parameterKey}
                hoverText={parameterValues.hoverText}
              />
              {parameterKey === "numberOfShares" && (
                <CustomTitleElement
                  heading="Company Uses Negative Payout Structure:"
                  hoverText={
                    OptionsFAQTopics.negative_payout_structure.short_desc
                  }
                  element={
                    <Switch
                      defaultChecked
                      checked={negativePayoutStructure}
                      onChange={() => {
                        setNegativePayoutStructure((prev) => !prev);
                      }}
                    />
                  }
                  style={{ margin: 15 }}
                />
              )}
            </div>
          )
        )}

        <div style={{ margin: 15 }}>
          <AwardsTable
            ticker={companyInfo.Ticker}
            awardParams={peerRankingParams}
            results={tableData}
            negativePayoutStructure={negativePayoutStructure}
            setAllAwardsValues={setAllAwardsValues}
            setTotalAwardValue={setTotalAwardValue}
          />
        </div>

        <center>
          <Button
            disabled={loading}
            onClick={() => setStartOptimisticHorseRace(true)}
          >
            Start Simulation
          </Button>
        </center>
        {optimisticLoading && (
          <center
            style={{
              height: "150px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PuffLoader
              color={"var(--loader)"}
              loading={optimisticLoading}
              size={75}
            />
          </center>
        )}

        {!optimisticLoading &&
        (numberOfTrialsCompleted < optimisticNumberOfTrials || loading) &&
        optimisticTableData[0] ? (
          <div style={{ marginTop: 20 }}>
            <hr style={{ margin: 15 }} />
            <h1>Results</h1>
            {optimisticTableData &&
            optimisticTableData[0] &&
            Object.keys(optimisticTableData[0]).includes(companyInfo.Ticker) ? (
              <>
                <h3 style={{ fontSize: 20, textAlign: "center" }}>
                  Chance Of Rank for {companyInfo.Ticker} (n=
                  {parseInt(
                    numberOfTrialsCompleted.toString().replaceAll(",", "")
                  ).toLocaleString()}
                  )
                  {numberOfTrialsCompleted === optimisticNumberOfTrials
                    ? " [Full Results Pending]"
                    : ""}
                </h3>
                <ResponsiveContainer width="100%" height={325}>
                  <BarChart
                    margin={{ top: 0, bottom: 30 }}
                    padding={{ left: 20 }}
                    // data={optimisticTableDataAsPercent}
                    data={optimisticTableData}
                  >
                    <CartesianGrid />
                    <XAxis dataKey="rank">
                      <Label
                        value="Rank"
                        offset={-10}
                        position="insideBottom"
                      />
                    </XAxis>

                    <YAxis
                      domain={[
                        0,
                        parseFloat(
                          (1 / tickersWithDataLength + 0.05).toFixed(3)
                        ),
                      ]}
                    >
                      <Label
                        value="Count for Rank"
                        offset={10}
                        angle={270}
                        position="insideLeft"
                        style={{ textAnchor: "middle" }}
                      />
                    </YAxis>
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar dataKey={companyInfo.Ticker} isAnimationActive={false}>
                      {optimisticTableData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={getColorByPercentile(
                            optimisticTableData[index][companyInfo.Ticker],
                            optimisticTableData.map(
                              (item) => item[companyInfo.Ticker]
                            )
                          )}
                        />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </>
            ) : (
              <h3 style={{ fontSize: 20, textAlign: "center" }}>
                Ranking Data not available for {companyInfo.Ticker}
              </h3>
            )}
          </div>
        ) : (
          !loading &&
          tableData[0] && (
            <div style={{ marginTop: 20 }}>
              <hr style={{ margin: 15 }} />
              <h1>Results</h1>
              {tableData &&
              tableData[0] &&
              Object.keys(tableData[0]).includes(companyInfo.Ticker) ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      margin: 15,
                    }}
                  >
                    <div>
                      <CustomTitleElement
                        heading={`Chance Of Rank for ${
                          companyInfo.Ticker
                        } (n=${parseInt(
                          numberOfTrialsCompletedFullRun
                            .toString()
                            .replaceAll(",", "")
                        ).toLocaleString()})`}
                        style={{ fontSize: 20, textAlign: "center" }}
                        hoverText={
                          OptionsFAQTopics.simulated_ranking_shape.short_desc
                        }
                      />
                    </div>
                  </div>
                  <ResponsiveContainer width="100%" height={325}>
                    <BarChart
                      margin={{ top: 0, bottom: 30 }}
                      padding={{ left: 20 }}
                      data={tableData}
                    >
                      <CartesianGrid />
                      <XAxis dataKey="rank">
                        <Label
                          value="Rank"
                          offset={-10}
                          position="insideBottom"
                        />
                      </XAxis>
                      <YAxis
                        domain={[
                          0,
                          parseFloat(
                            (1 / tickersWithDataLength + 0.05).toFixed(3)
                          ),
                        ]}
                      >
                        <Label
                          value="Chance of Rank"
                          offset={10}
                          angle={270}
                          position="insideLeft"
                          style={{ textAnchor: "middle" }}
                        />
                      </YAxis>
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip content={<CustomTooltip />} />

                      <Bar
                        dataKey={companyInfo.Ticker}
                        isAnimationActive={false}
                      >
                        {tableData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={getColorByPercentile(
                              tableData[index][companyInfo.Ticker],
                              tableData.map((item) => item[companyInfo.Ticker])
                            )}
                          />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </>
              ) : (
                <h3 style={{ fontSize: 20, textAlign: "center" }}>
                  Ranking Data not available for {companyInfo.Ticker}
                </h3>
              )}

              <div
                onClick={() => {
                  setShowAllResults((prev) => !prev);
                }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: 15,
                  cursor: "pointer",
                  color: "var(--link-blue)",
                  fontSize: 16,
                }}
              >
                {showAllResults ? "▲ Hide" : "▼ Show"} All Results Table
              </div>
              {showAllResults && (
                <div>
                  <h3 style={{ fontSize: 20 }}>
                    All Results (Chance Of Rank for all Companies)
                  </h3>
                  <Table
                    columns={tableColumns}
                    dataSource={tableData}
                    pagination={{ pageSize: 100, hideOnSinglePage: true }}
                    loading={loading}
                    scroll={{ x: 1300 }}
                  />
                </div>
              )}
            </div>
          )
        )}
      </div>
      <div style={{ margin: 15 }}>
        <AwardsResults
          allAwardsValues={allAwardsValues}
          totalAwardValue={totalAwardValue}
          negativePayoutStructure={negativePayoutStructure}
          numberOfTrialsCompleted={parseFloat(
            numberOfTrialsCompletedFullRun?.toString()?.replaceAll(",", "")
          )}
        />
      </div>
    </div>
  );
}
