import SIC from "../../assets/sic.json";
import { measureOptions } from "../ExecutiveData/executiveMeasure";

export default class dataFilter {
  constructor(data, currentYear, companies) {
    this.currentYear = parseInt(currentYear);
    this.data = data;
    this.companies = companies;

    // this.data = data.filter((item, index) => {
    //   return item?.compensation?.[this.currentYear]?.total;
    // });

    //// Filters out anything without total -- should be done on data side (not here)
    // this.data = data?.filter((item, index) => {
    //   const years = ["2018", "2019", "2020", "2021"];
    //   for (let y in years) {
    //     if (item?.compensation?.[years[y]]?.total) {
    //       return true;
    //     }
    //   }
    //   return false;
    // });
    // console.log(this.data.length);
  }

  filterCSuite = (csuite) => {
    const positionIsActive = (position) => {
      return csuite.some((pos) => pos.toLowerCase() === position);
    };

    if (csuite.includes("allExecutives")) {
      return new dataFilter(this.data, this.currentYear, this.companies);
    } else {
      //maybe switch positions to lowercase in data?
      const newData = this.data.filter((item) => {
        let bestYearMatch = 0;
        for (let posYear of Object.keys(item.position)) {
          if (parseInt(posYear) === this.currentYear) {
            bestYearMatch = posYear;
          }
          if (
            !bestYearMatch &&
            posYear > this.currentYear &&
            posYear > bestYearMatch &&
            !Object.keys(item.position).includes(this.currentYear)
          ) {
            bestYearMatch = posYear;
          }
        }
        if (bestYearMatch) {
          return (
            item.position?.[bestYearMatch] &&
            item.position?.[bestYearMatch].some(
              (pos) =>
                // parseInt(item?.compensation?.[bestYearMatch]?.total) >= 0 &&
                (positionIsActive("chair") &&
                  csuite.length === 1 &&
                  !pos.toLowerCase().includes("former") &&
                  !pos.toLowerCase().includes("advisor to") &&
                  (csuite.some((cs) => pos.includes(cs)) ||
                    pos.toLowerCase().includes("chair") ||
                    pos.toLowerCase().includes("chairman")) &&
                  !item.position?.[bestYearMatch].some(
                    (otherPos) =>
                      otherPos.toLowerCase().includes("ceo") ||
                      otherPos.toLowerCase().includes("chief executive") ||
                      otherPos.toLowerCase().includes("executive officer")
                  )) ||
                (((positionIsActive("chair") && csuite.length > 1) ||
                  !positionIsActive("chair")) &&
                  !pos.toLowerCase().includes("former") &&
                  !pos.toLowerCase().includes("advisor to") &&
                  (csuite.some((cs) => pos.includes(cs)) ||
                    (positionIsActive("chair") &&
                      (pos.toLowerCase().includes("chair") ||
                        pos.toLowerCase().includes("chairman"))) ||
                    (positionIsActive("ceo") &&
                      (pos.toLowerCase().includes("chief executive") ||
                        pos.toLowerCase().includes("executive officer"))) ||
                    (positionIsActive("cfo") &&
                      (pos.toLowerCase().includes("financial") ||
                        pos.toLowerCase().includes("finance"))) ||
                    (positionIsActive("cmo") &&
                      pos.toLowerCase().includes("marketing")) ||
                    (positionIsActive("coo") &&
                      (pos.toLowerCase().includes("operations") ||
                        pos.toLowerCase().includes("operating"))) ||
                    (positionIsActive("chro") &&
                      (pos.toLowerCase().includes("human resources") ||
                        pos.includes("HR"))) ||
                    (positionIsActive("cco") &&
                      pos.toLowerCase().includes("commercial")) ||
                    (positionIsActive("cso") &&
                      (pos.toLowerCase().includes("strategy") ||
                        pos.toLowerCase().includes("strategic"))) ||
                    (positionIsActive("cao") &&
                      (pos.toLowerCase().includes("administrative") ||
                        pos.toLowerCase().includes("administration"))) ||
                    (positionIsActive("clo") &&
                      (pos.toLowerCase().includes("legal") ||
                        pos.toLowerCase().includes("gc") ||
                        pos.toLowerCase().includes("chief counsel") ||
                        pos.toLowerCase().includes("general counsel"))) ||
                    (positionIsActive("cro") &&
                      pos.toLowerCase().includes("revenue")) ||
                    (positionIsActive("cto") &&
                      pos.toLowerCase().includes("technology")) ||
                    (positionIsActive("cio") &&
                      pos.toLowerCase().includes("investment")) ||
                    (positionIsActive("cbo") &&
                      pos.toLowerCase().includes("business")) ||
                    (positionIsActive("cpo") &&
                      pos.toLowerCase().includes("people")) ||
                    (positionIsActive("cdo") &&
                      pos.toLowerCase().includes("development")) ||
                    (positionIsActive("cmeo") &&
                      pos.toLowerCase().includes("medical")) ||
                    (positionIsActive("caco") &&
                      pos.toLowerCase().includes("accounting")) ||
                    (positionIsActive("csco") &&
                      pos.toLowerCase().includes("scientific")) ||
                    (positionIsActive("ccoo") &&
                      pos.toLowerCase().includes("compliance")) ||
                    (positionIsActive("cino") &&
                      pos.toLowerCase().includes("information")) ||
                    (positionIsActive("crio") &&
                      pos.toLowerCase().includes("risk")) ||
                    (positionIsActive("ccro") &&
                      pos.toLowerCase().includes("credit")) ||
                    (positionIsActive("cpro") &&
                      pos.toLowerCase().includes("product")) ||
                    (positionIsActive("cleo") &&
                      pos.toLowerCase().includes("lending")) ||
                    (positionIsActive("cproc") &&
                      pos.toLowerCase().includes("procurement")) ||
                    (positionIsActive("cdio") &&
                      pos.toLowerCase().includes("digital")) ||
                    (positionIsActive("csao") &&
                      pos.toLowerCase().includes("sales")) ||
                    (positionIsActive("ccuo") &&
                      pos.toLowerCase().includes("customer officer")) ||
                    (positionIsActive("ccxo") &&
                      pos.toLowerCase().includes("customer experience"))))
            )
          );
          // return item.includes(csuite.toUpperCase());
        }
        return false;
      });
      return new dataFilter(newData, this.currentYear, this.companies);
    }
  };

  salaryRange = (min, max) => {
    let newData = [];

    const minSalary =
      (min && parseInt(min.toString().replaceAll(",", ""))) || 0;
    const maxSalary =
      (max && parseInt(max.toString().replaceAll(",", ""))) || Infinity;

    newData = this.data.filter((item) => {
      return (
        item.compensation[this.currentYear]?.total >= minSalary &&
        item.compensation[this.currentYear]?.total <= maxSalary
      );
    });

    return new dataFilter(newData, this.currentYear, this.companies);
  };

  payRatioRange = (min, max) => {
    const getPayRatio = (company, year) => {
      return measureOptions.payRatio.getValue(company, year);
    };
    let newData = [];
    min = min.toString();
    max = max.toString();

    if (min.trim() !== "" && min.trim() !== "-") {
      min = parseInt(min.split(",").join(""));
    }
    if (max.trim() !== "" && max.trim() !== "-") {
      max = parseInt(max.split(",").join(""));
    }

    if (min === 0 && max === 1e5) {
      newData = this.data;
    } else if (min === 0) {
      newData = this.data.filter(
        (item) =>
          parseFloat(
            getPayRatio(this.companies[item.Ticker], this.currentYear)
          ) <= max
      );
    } else if (max === 1e5) {
      newData = this.data.filter(
        (item) =>
          parseFloat(
            getPayRatio(this.companies[item.Ticker], this.currentYear)
          ) >= min
      );
    } else {
      newData = this.data.filter(
        (item) =>
          parseFloat(
            getPayRatio(this.companies[item.Ticker], this.currentYear)
          ) >= min &&
          parseFloat(
            getPayRatio(this.companies[item.Ticker], this.currentYear)
          ) <= max
      );
    }

    return new dataFilter(newData, this.currentYear, this.companies);
  };

  executiveRankRange = (min, max) => {
    let newData = [];

    const minRank = (min && parseInt(min.toString().replaceAll(",", ""))) || 0;
    const maxRank =
      (max && parseInt(max.toString().replaceAll(",", ""))) || Infinity;
    if (minRank === 1 && maxRank === 10) {
      newData = this.data;
    } else if (minRank === 1) {
      newData = this.data.filter((item) => {
        return item.compensation[this.currentYear]?.rank <= maxRank;
      });
    } else if (maxRank === 10) {
      newData = this.data.filter((item) => {
        return item.compensation[this.currentYear]?.rank >= minRank;
      });
    } else {
      newData = this.data.filter((item) => {
        return (
          item.compensation[this.currentYear]?.rank >= minRank &&
          item.compensation[this.currentYear]?.rank <= maxRank
        );
      });
    }

    return new dataFilter(newData, this.currentYear, this.companies);
  };

  filterIndustry = (industries) => {
    let newData = [];
    if (industries.length === 0) {
      newData = this.data;
    } else {
      newData = this.data.filter((item) => {
        if (industries.includes(this.companies[item.Ticker].Industry)) {
          return true;
        }
        return false;
      });
    }
    return new dataFilter(newData, this.currentYear, this.companies);
  };

  filterSector = (sectors) => {
    let newData = [];
    let newSectors = [];
    if (sectors.length === 0) {
      newData = this.data;
    } else {
      for (let s in sectors) {
        newSectors.push(sectors[s].split(",")[0]);
        if (s == sectors.length - 1) {
          newData = this.data.filter((item) => {
            if (newSectors.includes(this.companies[item.Ticker].Sector)) {
              return true;
            }
            return false;
          });
        }
      }
    }
    return new dataFilter(newData, this.currentYear, this.companies);
  };

  filterSIC = (SICs) => {
    let newData = [];
    if (SICs.length === 0) {
      newData = this.data;
    } else {
      newData = this.data.filter((item) => {
        if (
          SICs.some((SICCode) =>
            this.companies[item.Ticker].SIC?.startsWith(SICCode)
          )
        ) {
          return true;
        }
        return false;
      });
    }
    return new dataFilter(newData, this.currentYear, this.companies);
  };

  filterCompany = (companies) => {
    let newCompanies = [];
    let newData = this.data;

    for (let c in companies) {
      newCompanies.push(companies[c].split(" [")[0]);
      if (c == companies.length - 1) {
        if (companies.length === 0) {
        } else {
          newData = this.data.filter((item) => {
            if (newCompanies.includes(this.companies[item.Ticker].Company)) {
              return true;
            }
            return false;
          });
        }
      }
    }
    return new dataFilter(newData, this.currentYear, this.companies);
  };

  filterCompanyLocation = (companyLocations) => {
    let newData = [];
    if (companyLocations.length === 0) {
      newData = this.data;
    } else {
      newData = this.data.filter((item) => {
        if (
          this.companies[item.Ticker]?.State &&
          companyLocations.includes(this.companies[item.Ticker].State)
        ) {
          return true;
        }
        return false;
      });
    }
    return new dataFilter(newData, this.currentYear, this.companies);
  };

  filterCompanyCity = (companyCities) => {
    let newData = [];
    if (companyCities.length === 0) {
      newData = this.data;
    } else {
      newData = this.data.filter((item) => {
        for (let c in companyCities) {
          if (
            this.companies[item.Ticker]?.City &&
            companyCities[c]
              .split(",")[0]
              .toLowerCase()
              .includes(this.companies[item.Ticker].City?.toLowerCase()) &&
            companyCities[c]
              .split(",")[1]
              .includes(this.companies[item.Ticker].State)
          ) {
            return true;
          }
        }
        return false;
      });
    }
    return new dataFilter(newData, this.currentYear, this.companies);
  };

  filterExecutive = (executives) => {
    let newExecutives = [];
    let newData = this.data;

    for (let e in executives) {
      newExecutives.push(executives[e].split(" [")[0]);
      if (e == executives.length - 1) {
        if (executives.length === 0) {
        } else {
          newData = this.data.filter((item) => {
            if (newExecutives.includes(item.name)) {
              return true;
            }
            return false;
          });
        }
      }
    }
    return new dataFilter(newData, this.currentYear, this.companies);
  };

  filterExecutiveState = (executiveStates) => {
    let newData = [];
    if (executiveStates.length === 0) {
      newData = this.data;
    } else {
      newData = this.data.filter((item) => {
        if (executiveStates.includes(item.executiveState)) {
          return true;
        }
        return false;
      });
    }
    return new dataFilter(newData, this.currentYear, this.companies);
  };

  filterExecutiveCity = (executiveCities) => {
    let newData = [];
    if (executiveCities.length === 0) {
      newData = this.data;
    } else {
      newData = this.data.filter((item) => {
        for (let e in executiveCities) {
          if (
            executiveCities[e]
              .toLowerCase()
              .split(",")[0]
              .includes(item.executiveCity.toLowerCase()) &&
            executiveCities[e].split(",")[1].includes(item.executiveState)
          ) {
            return true;
          }
        }
        return false;
      });
    }
    return new dataFilter(newData, this.currentYear, this.companies);
  };

  marketCapRange = (minCap, maxCap) => {
    let newData = [];

    const min =
      (minCap && parseInt(minCap.toString().replaceAll(",", ""))) || "";
    const max =
      (maxCap && parseInt(maxCap.toString().replaceAll(",", ""))) || "";

    if (min === "" && max === "") {
      newData = this.data;
    } else if (min === "") {
      newData = this.data.filter(
        (item) =>
          convertMarketCap(this.companies[item.Ticker]?.MarketCap) <= max
      );
    } else if (max === "") {
      newData = this.data.filter(
        (item) =>
          convertMarketCap(this.companies[item.Ticker]?.MarketCap) >= min
      );
    } else {
      newData = this.data.filter(
        (item) =>
          convertMarketCap(this.companies[item.Ticker]?.MarketCap) >= min &&
          convertMarketCap(this.companies[item.Ticker]?.MarketCap) <= max
      );
    }
    return new dataFilter(newData, this.currentYear, this.companies);
  };

  employeeRange = (minEmp, maxEmp) => {
    let newData = [];
    const min =
      minEmp === 0
        ? 0
        : (minEmp && parseInt(minEmp.toString().replaceAll(",", ""))) || "";
    const max =
      maxEmp === 0
        ? 0
        : (maxEmp && parseInt(maxEmp.toString().replaceAll(",", ""))) || "";

    if (min === "" && max === "") {
      newData = this.data;
    } else if (min === 0 && max === "") {
      newData = this.data;
    } else if (min === "" || min === 0) {
      newData = this.data.filter(
        (item) => this.companies[item.Ticker]?.Employees <= max
      );
    } else if (max === "") {
      newData = this.data.filter(
        (item) => this.companies[item.Ticker]?.Employees >= min
      );
    } else {
      newData = this.data.filter(
        (item) =>
          this.companies[item.Ticker]?.Employees >= min &&
          this.companies[item.Ticker]?.Employees <= max
      );
    }
    return new dataFilter(newData, this.currentYear, this.companies);
  };

  percentileCompensation = (min, max) => {
    let newData = [];

    if (min === 0 && max === 100) {
      return new dataFilter(this.data, this.currentYear, this.companies);
    }
    const sorted = this.data.sort((a, b) => {
      if (
        a.compensation?.[this.currentYear]?.total &&
        b.compensation?.[this.currentYear]?.total
      ) {
        return (
          a.compensation[this.currentYear].total -
          b.compensation[this.currentYear].total
        );
      }
    });
    const len = sorted.length;
    const lowerPos = Math.floor(((len - 1) * min) / 100);
    const upperPos = Math.floor(((len - 1) * max) / 100);
    newData = sorted.slice(lowerPos, upperPos + 1);
    return new dataFilter(newData, this.currentYear, this.companies);
  };

  revenueRange = (minRevenue, maxRevenue) => {
    let newData = [];

    let min, max;

    if (minRevenue.trim() !== "" && minRevenue.trim() !== "-") {
      min = parseInt(minRevenue.split(",").join(""));
    }
    if (maxRevenue.trim() !== "" && maxRevenue.trim() !== "-") {
      max = parseInt(maxRevenue.split(",").join(""));
    }

    if (min === undefined && max === undefined) {
      newData = this.data;
    } else if (min === undefined) {
      newData = this.data.filter(
        (item) =>
          this.companies[item.Ticker]?.Revenue?.[this.currentYear]?.value <= max
      );
    } else if (max === undefined) {
      newData = this.data.filter(
        (item) =>
          this.companies[item.Ticker]?.Revenue?.[this.currentYear]?.value >= min
      );
    } else {
      newData = this.data.filter(
        (item) =>
          this.companies[item.Ticker]?.Revenue?.[this.currentYear]?.value >=
            min &&
          this.companies[item.Ticker]?.Revenue?.[this.currentYear]?.value <= max
      );
    }

    return new dataFilter(newData, this.currentYear, this.companies);
  };

  assetsRange = (minAssets, maxAssets) => {
    let newData = [];

    let min, max;

    if (minAssets.trim() !== "" && minAssets.trim() !== "-") {
      min = parseInt(minAssets.split(",").join(""));
    }
    if (maxAssets.trim() !== "" && maxAssets.trim() !== "-") {
      max = parseInt(maxAssets.split(",").join(""));
    }

    if (min === undefined && max === undefined) {
      newData = this.data;
    } else if (min === undefined) {
      newData = this.data.filter(
        (item) =>
          this.companies[item.Ticker]?.Measures?.Assets?.[this.currentYear] <=
          max
      );
    } else if (max === undefined) {
      newData = this.data.filter(
        (item) =>
          this.companies[item.Ticker]?.Measures?.Assets?.[this.currentYear] >=
          min
      );
    } else {
      newData = this.data.filter(
        (item) =>
          this.companies[item.Ticker]?.Measures?.Assets?.[this.currentYear] >=
            min &&
          this.companies[item.Ticker]?.Measures?.Assets?.[this.currentYear] <=
            max
      );
    }

    return new dataFilter(newData, this.currentYear, this.companies);
  };

  netIncomeRange = (minNetIncome, maxNetIncome) => {
    let newData = [];

    let min, max;

    if (minNetIncome.trim() !== "" && minNetIncome.trim() !== "-") {
      min = parseInt(minNetIncome.split(",").join(""));
    }
    if (maxNetIncome.trim() !== "" && maxNetIncome.trim() !== "-") {
      max = parseInt(maxNetIncome.split(",").join(""));
    }

    if (min === undefined && max === undefined) {
      newData = this.data;
    } else if (min === undefined) {
      newData = this.data.filter(
        (item) =>
          this.companies[item.Ticker]?.NetIncome?.[this.currentYear]?.value <=
          max
      );
    } else if (max === undefined) {
      newData = this.data.filter(
        (item) =>
          this.companies[item.Ticker]?.NetIncome?.[this.currentYear]?.value >=
          min
      );
    } else {
      newData = this.data.filter(
        (item) =>
          this.companies[item.Ticker]?.NetIncome?.[this.currentYear]?.value >=
            min &&
          this.companies[item.Ticker]?.NetIncome?.[this.currentYear]?.value <=
            max
      );
    }

    return new dataFilter(newData, this.currentYear, this.companies);
  };

  companyRankRange = (minRank, maxRank, filteredMarketCap) => {
    let newData = [];
    const min =
      (minRank && parseInt(minRank.toString().replaceAll(",", ""))) || "";
    const max =
      (maxRank && parseInt(maxRank.toString().replaceAll(",", ""))) || "";
    if (filteredMarketCap) {
      if (min === "" && max === "") {
        newData = this.data;
      } else if (min === "") {
        newData = this.data.filter(
          (item) => filteredMarketCap.get(item.Ticker) + 1 <= max
        );
      } else if (max === "") {
        newData = this.data.filter(
          (item) => filteredMarketCap.get(item.Ticker) + 1 >= min
        );
      } else {
        newData = this.data.filter(
          (item) =>
            filteredMarketCap.get(item.Ticker) + 1 >= min &&
            filteredMarketCap.get(item.Ticker) + 1 <= max
        );
      }
    }
    return new dataFilter(newData, this.currentYear, this.companies);
  };

  filterIndexes = (index) => {
    let newData = [];
    if (index.length === 0) {
      newData = this.data;
    } else {
      let indexTickers = index; //?.[this.currentYear]?.Tickers;
      newData = this.data.filter((item) => {
        if (indexTickers.includes(item.Ticker)) {
          return true;
        }
        return false;
      });
    }
    return new dataFilter(newData, this.currentYear, this.companies);
  };

  filterFounders = (founders) => {
    let newData = [];
    if (founders === "all") {
      newData = this.data;
    } else {
      newData = this.data.filter((item) => {
        let bestYearMatch = 0;
        for (let posYear of Object.keys(item.position)) {
          if (parseInt(posYear) === this.currentYear) {
            bestYearMatch = posYear;
          }
          if (
            !bestYearMatch ||
            (posYear > this.currentYear &&
              posYear > bestYearMatch &&
              !Object.keys(item.position).includes(this.currentYear))
          ) {
            bestYearMatch = posYear;
          }
        }
        if (
          founders === "non-founders" &&
          item.position?.[bestYearMatch].length === 0
        ) {
          return true;
        }
        if (bestYearMatch) {
          return (
            item.position?.[bestYearMatch] &&
            item.position?.[bestYearMatch]
              .map(
                (pos) =>
                  (founders === "founders" &&
                    pos.toLowerCase().includes("founder")) ||
                  (founders === "non-founders" &&
                    !pos.toLowerCase().includes("founder"))
              )
              .some((e) => e === true)
          );
        }
        return false;
      });
    }
    return new dataFilter(newData, this.currentYear, this.companies);
  };

  filterMeasure = (minMeasure, maxMeasure, measureKey) => {
    let newData = [];
    let min, max;
    minMeasure = minMeasure.toString();
    maxMeasure = maxMeasure.toString();

    if (minMeasure.trim() !== "" && minMeasure.trim() !== "-") {
      min = parseFloat(minMeasure.split(",").join(""));
    }
    if (maxMeasure.trim() !== "" && maxMeasure.trim() !== "-") {
      max = parseFloat(maxMeasure.split(",").join(""));
    }

    if (min === undefined && max === undefined) {
      newData = this.data;
    } else if (min === undefined) {
      newData = this.data.filter(
        (item) =>
          measureOptions[measureKey].getValue(
            this.companies[item.Ticker],
            this.currentYear
          ) <= max
      );
    } else if (max === undefined) {
      newData = this.data.filter(
        (item) =>
          measureOptions[measureKey].getValue(
            this.companies[item.Ticker],
            this.currentYear
          ) >= min
      );
    } else {
      newData = this.data.filter(
        (item) =>
          measureOptions[measureKey].getValue(
            this.companies[item.Ticker],
            this.currentYear
          ) >= min &&
          measureOptions[measureKey].getValue(
            this.companies[item.Ticker],
            this.currentYear
          ) <= max
      );
    }

    // // To test if filters are working properly
    // if (measureKey === "OCF_Assets") {
    //   let greatestVal = 0;
    //   let lowestVal = 0;
    //   for (const company of newData) {
    //     const currentVal = parseFloat(
    //       measureOptions[measureKey].getValue(company, this.currentYear)
    //     );
    //     if (currentVal > greatestVal && currentVal !== Infinity) {
    //       console.log("greatestVal - ", currentVal.toLocaleString());
    //       greatestVal = currentVal;
    //     }
    //     if (currentVal < lowestVal) {
    //       console.log("lowestVal - ", currentVal.toLocaleString());
    //       lowestVal = currentVal;
    //     }
    //     // console.log(
    //     //   measureOptions[measureKey]
    //     //     .getValue(company, this.currentYear)
    //     //     ?.toLocaleString()
    //     // );
    //   }
    // }

    return new dataFilter(newData, this.currentYear, this.companies);
  };

  calculatedDiffAllowed = (diffPercentAllowed) => {
    const newData = this.data.filter((item) => {
      const compensationDiff = !item.compensation[this.currentYear]
        ?.sumOfCompensationComponents
        ? 0
        : Math.abs(
            item.compensation[this.currentYear]?.total -
              item.compensation[this.currentYear]?.sumOfCompensationComponents
          );

      // 100 percent should allow all, even if missing comp or total data
      if (
        compensationDiff === 0 ||
        ((!item.compensation[this.currentYear]?.total ||
          !item.compensation[this.currentYear]?.sumOfCompensationComponents) &&
          diffPercentAllowed === 100)
      ) {
        return true;
      }

      return (
        compensationDiff <= 2 ||
        parseFloat(
          (100 * compensationDiff) /
            Math.max(
              item.compensation[this.currentYear]?.total,
              item.compensation[this.currentYear]?.sumOfCompensationComponents
            )
        ) <= diffPercentAllowed
      );
    });

    return new dataFilter(newData, this.currentYear, this.companies);
  };
}

const convertMarketCap = (cap) => {
  if (!cap) return null;
  return cap;
};
