import React, { useState, useEffect, useMemo, useRef } from "react";
import "../css/overview.css";
import { useDispatch, useSelector } from "react-redux";
import {
  compAISearch,
  clearCompAIResults,
  compAISearchAvailability,
  compAIResultRating,
} from "../redux/actions/CompanyAction";
import { Input, Button, Tooltip, Switch, Select, Rate } from "antd";
import { COMP_AI_REGEX } from "../utils/Constants/systemSetting";
import toast from "react-hot-toast";
import showdown from "showdown";
import DOMPurify from "dompurify";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  MessageGroup,
  TypingIndicator,
  Conversation,
  Avatar,
  Sidebar,
  ConversationList,
  ExpansionPanel,
} from "@chatscope/chat-ui-kit-react";
import { v4 as uuidv4 } from "uuid";

const { Search } = Input;

// To convert markdown results
const converter = new showdown.Converter();

const sanitizedHTML = (result) =>
  DOMPurify.sanitize(converter.makeHtml(result));

const aiSampleQuestions = [
  "10K Questions:",
  "What are the last 3 years revenues? List it in a tabular format if possible",
  "What are the last 3 years net incomes? List it in a tabular format if possible",
  "What are the last 3 years total assets? List it in a tabular format if possible",
  "What is the total employee count?",
  "Looking at the financial statements, is this company performance trend good, bad, or neutral. Explain your choice.",
  "Has there been any changes to the risk profile of this company? Explain your answer.",
  "Explain in 5 sentences or less what this company does or produces.",
  "What is the vision statement of this company?",
  "Compare the performance of the company to its compensation peers or performance peers over the last 3 years. You can use financial or operating metrics or numbers in the financial statements. Present in the information in easily consumable ways such as charts, graphs, tables, and a narrative.",
  "Produce a list of financial ratios for this company. To help you with this I am including a list of potential ratios and metrics you can calculate based on inputs in column A of the spreadsheet entitled ‘Financial Ratios’. Column E includes some explanations to guide you to what the ratios in column A are about. You can either fill out the spreadsheet itself or extract the metrics and ratios from column A and present your answer here in tabular format based on the last 3 years of this company’s financial statements.",
  "Proxy Questions:",
  "What companies are in the compensation peer group?",
  "What companies are in the performance peer group?",
  "How much compensation was delivered to Named Executive Officers last year? What about last 3 years?",
  "How much was each neo compensated over the last three years according to the summary compensation table and break it down by executive and year",
  "What is the target total compensation opportunity for executives? Break it down by: a. annualized base salary rate (which many times is different than the value reported in the summary compensation table) b. annual cash incentive target opportunity value c. long-term incentive target award value",
  "What is the design of the short-term incentive program? Break it down by: a. Target opportunity levels for execs b. Range of payout opportunity (threshold as a % of target, max as a % of target) c. Metrics, associated weightings, performance hurdles at threshold, target, max d. Performance for the fiscal year being reported e. Any design elements reported for next fiscal year (e.g. a company reported what they have set as the plan for 2023 fiscal year in their proxy disclosing actions taken during 2022)",
  "What is the design of the long-term incentive program? Break it down by: a. Target opportunity levels for execs b. Range of payout opportunity (threshold as a % of target, max as a % of target) c. How award opportunity is split across vehicles (Restricted stock/units, performance shares, options, phantom stock, etc.) d. Vesting schedule for awards e. Performance metrics, associated weightings, performance hurdles at threshold, target, max f. Performance peer group if applicable g. Any design elements reported for next fiscal year (e.g. a company reported what they have set as the plan for 2023 fiscal year in their proxy disclosing actions taken during 2022)",
  "How much compensation was provided to the board of directors last year? Break it down by names.",
  "How many meetings did the board and each of its committees have?",
  "What is the design of the board compensation program? Break it down by: a. Annual cash retainers i. General board service ii. Committee leadership/membership iii. Board leadership b. Equity retainers i. General board service ii. Committee leadership/membership iii. Board leadership c. How are equity retainer amounts determined? Targeted $ value or # of shares? d. What vehicle is used to deliver? What is the vesting?",
  "What are the equity ownership guidelines?",
  "What are the benefits to be delivered in the event of severance scenarios? Such as termination without cause, resignation, in the context of a change of control, etc.",
  "From the CD&A section get me the executive compensation data (FYE or forward disclosed) for base salaries, annual target bonus and LTI",
  "Get me all the data from the outstanding equity awards table",
  "Get me all the Director pay data including annual cash/equity retainers, committee member retainers, committee chair retainers and board leadership retainers (COB/LID)",
  "Get me all the share usage (shares granted, cancelled/expired/forfeited/assumed, outstanding) (for options: WAEP and BS assumptions), WASO (basic/diluted), CSO",
  "Who is the chair of the compensation committee?",
  "Give me the age, education, and qualifications of the board and list any other companies where they serve as a board member",
  "What other companies could these board members qualify to be a board member of and give me your reasoning as to what would make them qualified for each company.",
  // "Fill in the placeholders in the JSON code presented below using data in the Summary Compensation Table which covers multiple years of data for named executives. Do this for every year of data available in the Summary Compensation Table for every executive named in the table. I also like to split the positions at “and” and “;” and “,” so copy the same format in the code.",
];

const defaultReportTypesAvailable = [
  { value: "CombinedReports", label: "Combined Reports" },
  {
    value: "CombinedReports_750",
    label: "Combined Reports",
    chunkSize: "750",
  },
  {
    value: "CombinedReports_100",
    label: "Combined Reports",
    chunkSize: "100",
  },
  { value: "ProxyStatements", label: "Proxy Statement" },
  { value: "AnnualReports", label: "Annual Report" },
];

export default function CompanyCalculator({ companyInfo }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.UserReducer);
  const { subscription } = useSelector((state) => state.CustomerReducer);
  const isTier2 = useMemo(() => {
    if (user?.tier2access || subscription?.plan?.metadata?.isTier2 === "true") {
      return true;
    }
    return false;
  }, [user, subscription]);

  const compAIaccess = useMemo(() => {
    if (user?.compAIaccess) {
      return true;
    }
    return false;
  }, [user]);

  const { compAIResults, compAIAvailability } = useSelector(
    (state) => state.CompanyReducer
  );

  const [searchQuery, setSearchQuery] = useState("");
  const [searchAllCompanies, setSearchAllCompanies] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchErrorMessage, setSearchErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [noResultsForSearchQuery, setNoResultsForSearchQuery] = useState("");
  const [page, setPage] = useState(1);

  const [userMessages, setUserMessages] = useState([]);
  const [compAIMessages, setCompAIMessages] = useState([]);
  const [allMessages, setAllMessages] = useState([]);

  const [messageID, setMessageID] = useState(null);

  useEffect(() => {
    setSearchAllCompanies(false);
  }, [companyInfo, dispatch]);

  const [newSession, setNewSession] = useState(true);
  const [sessionID, setSessionID] = useState("");

  const [reportYearOptions, setReportYearOptions] = useState([
    { value: "2024", label: "2024" },
    { value: "2023", label: "2023" },
  ]);
  const [reportTypeOptions, setReportTypeOptions] = useState([
    { value: "CombinedReports", label: "Combined Reports" },
    { value: "ProxyStatements", label: "Proxy Statement" },
    { value: "AnnualReports", label: "Annual Report" },
  ]);

  const [selectingModel, setSelectingModel] = useState(true);
  const [reportYearSelected, setReportYearSelected] = useState("");
  const [reportTypeSelected, setReportTypeSelected] =
    useState("CombinedReports");
  const [chunkSize, setChunkSize] = useState("");

  const [noDataAvailable, setNoDataAvailable] = useState(false);

  useEffect(() => {
    if (newSession) {
      setSessionID(uuidv4());
      const token = localStorage.getItem("ACCESS_TOKEN");
      dispatch(
        compAISearchAvailability({
          ticker: companyInfo.Ticker,
          token,
        })
      );
      dispatch(clearCompAIResults());
      setAllMessages([]);
      setUserMessages([]);
      setCompAIMessages([]);
      setNewSession(false);
    }
  }, [dispatch, newSession, companyInfo]);

  useEffect(() => {
    const compAIDataAvailable = compAIAvailability?.data;
    if (
      !compAIDataAvailable ||
      !Object.keys(compAIDataAvailable) ||
      !Object.keys(compAIDataAvailable).length
    ) {
      setNoDataAvailable(true);
      return;
    }
    setNoDataAvailable(false);
    const reportYearsAvailable = Object.keys(compAIDataAvailable);
    setReportYearOptions(
      reportYearsAvailable.map((item, key) => ({
        label: item,
        value: item,
        key,
      }))
    );
    const reportTypesAvailable = compAIDataAvailable[reportYearSelected];
    if (!reportTypesAvailable) return;

    setReportTypeOptions(
      defaultReportTypesAvailable.filter((item) =>
        reportTypesAvailable.includes(item.value)
      )
    );
  }, [compAIAvailability, reportYearSelected, companyInfo]);

  useEffect(() => {
    setSearchQuery("");
    setSearchResults("");
    setSearchErrorMessage(null);

    if (!companyInfo?.CIK && !searchAllCompanies) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [companyInfo, searchAllCompanies, dispatch]);

  useMemo(() => {
    setLoading(false);
    if (compAIResults?.data) {
      setSearchResults(compAIResults.data);
      setMessageID(compAIResults.id);
    }
    if (compAIResults?.error) {
      toast.error(compAIResults.error);
      setSearchErrorMessage(compAIResults.error);
    }
  }, [compAIResults]);

  useMemo(() => {
    if (searchResults?.length === 0 && !noResultsForSearchQuery) {
      setNoResultsForSearchQuery(searchQuery);
    }
  }, [noResultsForSearchQuery, searchResults, searchQuery]);

  const handleChange = (value) => {
    //If changing value make sure to change it in
    value = value.replace(COMP_AI_REGEX, "").slice(0, 500);
    setSearchQuery(value);
  };

  const AIModelOptions = ["Model T-2-mini", "Model T-2", "Model S-2"];
  const [AIModelSelected, setAIModelSelected] = useState(AIModelOptions[0]);

  // Customer facing names
  const AIModelNameMap = {
    "Model T-2-mini": "K2",
    "Model T-2": "HAL 1000",
    "Model S-2": "Fuji",
  };

  const handleSubmit = async () => {
    setUserMessages((prev) => [
      ...prev,
      <Message
        model={{
          message: searchQuery,
          sentTime: new Date().getTime().toString(),
          sender: "user",
          direction: "outgoing",
        }}
      />,
    ]);
    const token = localStorage.getItem("ACCESS_TOKEN");
    setNoResultsForSearchQuery("");
    // setSearchResults(null);
    setSearchErrorMessage(null);
    setLoading(true);
    setSearchQuery("");
    dispatch(
      compAISearch({
        ticker: companyInfo.Ticker,
        sessionID,
        reportTypeSelected,
        reportYearSelected,
        AIModelSelected,
        searchQuery,
        chunkSize,
        token,
        messageID: uuidv4(),
      })
    );
  };

  useMemo(() => {
    if (searchResults.length > 0) {
      setCompAIMessages((prev) => [
        ...prev,
        <Message
          model={{
            sentTime: new Date().getTime().toString(),
            sender: "Comp-AI",
            direction: "incoming",
            type: "html",
            payload: sanitizedHTML(searchResults),
            key: searchResults.indexOf(),
            id: messageID,
          }}
          id={messageID}
        />,
      ]);
    }
  }, [searchResults, messageID]);

  useMemo(() => {
    setAllMessages(
      [...userMessages, ...compAIMessages].sort(
        (a, b) =>
          parseInt(a.props.model.sentTime) - parseInt(b.props.model.sentTime)
      )
    );
  }, [userMessages, compAIMessages]);

  //Prevent pasting of html/css styles
  const handlePaste = (event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData("text/plain");
    handleChange(pastedText);
  };

  useMemo(() => {
    if (!reportYearOptions || !reportYearOptions.length) return;
    if (
      !reportYearOptions.map((item) => item.value).includes(reportYearSelected)
    )
      setReportYearSelected(reportYearOptions[0].value);
  }, [reportYearOptions, reportYearSelected]);
  useMemo(() => {
    if (!reportTypeOptions || !reportTypeOptions.length) return;
    if (
      !reportTypeOptions.map((item) => item.value).includes(reportTypeSelected)
    ) {
      setReportTypeSelected(reportTypeOptions[0].value);
      setChunkSize(reportTypeOptions[0]?.chunkSize || "");
    }
  }, [reportTypeOptions, reportTypeSelected]);

  const updateResponseRating = (messageID, rating) => {
    const token = localStorage.getItem("ACCESS_TOKEN");
    dispatch(compAIResultRating({ token, messageID, rating }));
  };

  return (
    <>
      {!isTier2 ? (
        <div
          className="comp-ai-section col-lg-9 executives "
          style={{ height: "100%", padding: 50 }}
        >
          <h3
            style={{
              textAlign: "center",
              fontWeight: "normal",
              fontSize: 22,
            }}
          >
            <p style={{ fontWeight: "bold" }}>
              This page is for C-Suite Comp&reg; Enterprise users only.
            </p>
            <br />
            <p>
              {" "}
              Contact your account manager to access Comp AI™, the AI assistant
              that enables you to ask questions in plain language of any company
              in our platform.
            </p>
          </h3>
        </div>
      ) : (
        <>
          <div
            className="comp-ai-section col-lg-9 executives p-0"
            style={{ height: "100%" }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "flex-start",
                justifyContent: "center",
                marginBottom: "20px",
                marginLeft: "20px",
                width: "100%",
                height: "100%",
              }}
            >
              <div style={{ width: "85%", marginBottom: 20 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    className="logo"
                    style={{
                      maxWidth: 100,
                      width: "63%",
                      marginBottom: "10px",
                      marginRight: "15px",
                    }}
                    src="/logos/CompAILogo.png"
                    alt="Logo"
                  />
                  <h3 style={{ textAlign: "center" }}>Comp AI™</h3>
                </div>
                <p style={{ fontSize: "1.05em" }}>
                  The days of endlessly scouring proxy and financial filings for
                  information is over. Comp AI™ is your AI assistant that
                  enables you to ask questions in plain language of any company
                  in our platform. See our prompt library for examples of
                  questions you might ask. View the tutorial to learn how you
                  can maximize its usage. Go ahead, give it a try.
                </p>
              </div>
              {noDataAvailable ? (
                <h3
                  style={{
                    textAlign: "center",
                    fontSize: "2em",
                    marginTop: 50,
                  }}
                >
                  Comp AI™ for {companyInfo.Company} is coming soon.
                </h3>
              ) : (
                <div className="container">
                  <div
                    style={{
                      width: "100%",
                      height: "600px",
                      userSelect: "text",
                    }}
                  >
                    <MainContainer responsive>
                      {selectingModel && (
                        <Sidebar position="left">
                          <ConversationList>
                            {AIModelOptions.map((modelName, index) => (
                              <Conversation
                                active={modelName === AIModelSelected}
                                // info="Yes i can do it for you"
                                // lastSenderName={modelName}
                                name={AIModelNameMap[modelName]}
                                onClick={() => {
                                  setAIModelSelected(modelName);
                                  // setSelectingModel(false);
                                  setNewSession(true);
                                }}
                                key={index}
                              >
                                <Avatar
                                  name={AIModelNameMap[modelName]}
                                  src={`/avatars/${modelName}.png`}
                                />
                              </Conversation>
                            ))}
                          </ConversationList>
                        </Sidebar>
                      )}
                      <ChatContainer>
                        <MessageList
                          typingIndicator={
                            loading ? (
                              <TypingIndicator content="Comp AI™ is thinking..." />
                            ) : null
                          }
                        >
                          <Message
                            model={{
                              message: `Hello, I am Comp AI™ Agent ${AIModelNameMap[AIModelSelected]}. Have questions about ${companyInfo.Company}? Ask away. Please be as descriptive and precise as possible to get the best answers.`,
                              sentTime: new Date().getTime().toString(),
                              sender: "Comp-AI",
                              direction: "incoming",
                            }}
                          />

                          {allMessages?.length > 0 &&
                            allMessages.map((message, index) => (
                              <>
                                {message}
                                {(index + 1) % 2 === 0 && (
                                  <p style={{ fontSize: "14px" }}>
                                    Rate Response Quality:&nbsp;&nbsp;
                                    <Rate
                                      onChange={(val) =>
                                        updateResponseRating(
                                          message.props.id,
                                          val
                                        )
                                      }
                                      style={{ fontSize: "14px" }}
                                    />
                                  </p>
                                )}
                              </>
                            ))}
                        </MessageList>
                        <MessageInput
                          placeholder="Type your question here..."
                          onChange={(textContent) => handleChange(textContent)}
                          onSend={() => handleSubmit()}
                          attachButton={false}
                          onPaste={handlePaste}
                          value={searchQuery}
                          sendDisabled={!searchQuery}
                          // onClick={() => setSelectingModel(false)}
                        />
                      </ChatContainer>
                    </MainContainer>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      marginTop: 20,
                    }}
                  >
                    <h3 style={{ fontSize: 20, marginTop: 4, width: 200 }}>
                      Sample&nbsp;Questions:
                    </h3>
                    <div style={{ height: 20, marginLeft: 10, width: "100%" }}>
                      <Select
                        defaultValue="Prefill a question"
                        value={
                          aiSampleQuestions.some((item) => item === searchQuery)
                            ? aiSampleQuestions.find(
                                (item) => item === searchQuery
                              )
                            : "Prefill a question"
                        }
                        onChange={(value) => {
                          setSearchQuery(value);
                        }}
                        options={aiSampleQuestions.map((item) => ({
                          label: item,
                          value: item,
                          disabled: item.includes("Questions:"),
                        }))}
                        style={{ width: "100%", textAlign: "center" }}
                        dropdownStyle={{ textAlign: "left" }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      marginTop: 15,
                    }}
                  >
                    <h3 style={{ fontSize: 20 }}>
                      Select a Different Agent (Currently using{" "}
                      {AIModelNameMap[AIModelSelected]})
                    </h3>
                    <div style={{ height: 38, marginLeft: 10 }}>
                      <Switch
                        checked={selectingModel}
                        onChange={() => setSelectingModel((prev) => !prev)}
                      />
                    </div>
                  </div>
                  {/* <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      // marginTop: 20,
                    }}
                  >
                    <h3 style={{ fontSize: 20, marginTop: 4, width: 115 }}>
                      Report Type:
                    </h3>
                    <div style={{ height: 20, marginLeft: 10 }}>
                      <Select
                        defaultValue="2024"
                        value={reportTypeSelected}
                        onChange={(value) => {
                          setReportTypeSelected(value);
                          setReportYearSelected(reportYearOptions[0].value);
                        }}
                        options={reportTypeOptions}
                        style={{ width: 200, textAlign: "center" }}
                        dropdownStyle={{ textAlign: "center" }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      // marginTop: 20,
                    }}
                  >
                    <h3 style={{ fontSize: 20, marginTop: 4, width: 115 }}>
                      Report Year:
                    </h3>
                    <div style={{ height: 20, marginLeft: 10 }}>
                      <Select
                        defaultValue="2024"
                        value={
                          reportTypeSelected === "CombinedReports"
                            ? "All Years Available"
                            : reportYearSelected
                        }
                        disabled={reportTypeSelected === "CombinedReports"}
                        onChange={(value) => setReportYearSelected(value)}
                        options={reportYearOptions}
                        style={{ width: 200, textAlign: "center" }}
                        dropdownStyle={{ textAlign: "center" }}
                      />
                    </div>
                  </div> */}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
