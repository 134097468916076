import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSubscription } from "../../../redux/actions/CustomerAction";
import moment from "moment";
import PurchaseHistory from "./PurchaseHistory";
import { Button } from "../../../JSS/Button";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Billing from "./Billing";
import { Link } from "react-router-dom";
import { PLAN_NAME } from "../../../utils/Constants/systemSetting";
import {
  STRIPE_KEY_2022,
  STRIPE_KEY_OLD,
} from "../../../utils/Constants/systemSetting";
const PUBLIC_KEY = STRIPE_KEY_2022;
const stripeTestPromise = loadStripe(PUBLIC_KEY);

export default function Membership() {
  const { subscription, invoices } = useSelector(
    (state) => state.CustomerReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSubscription());
  }, []);

  const [canceledRenewal, setCanceledRenewal] = useState();
  const [subscriptionStatus, setSubscriptionStatus] = useState("");
  useEffect(() => {
    if (["active", "trialing"].includes(subscription?.status)) {
      if (!subscription?.cancel_at_period_end) {
        setSubscriptionStatus("active");
      } else {
        setSubscriptionStatus("canceling");
      }
    } else {
      setSubscriptionStatus("expired");
    }
  }, [subscription]);

  // const expire = subscription.current_period_end;
  // console.log(new Date(expire * 1000));
  // console.log(expire, Date.now());
  // console.log(moment(expire * 1000).format("MMMM Do"));

  // console.log(subscription);

  const renderInvoices = () => {
    const formattedInvoices =
      invoices &&
      invoices?.data.map((invoice, index) => {
        return invoice?.subtotal > 0 ? (
          <PurchaseHistory invoice={invoice} key={index} />
        ) : null;
      });
    if (formattedInvoices.every((invoice) => invoice === null)) {
      return <div>No recent billing history.</div>;
    }
    return formattedInvoices;
  };

  const getPlanName = (interval) => {
    if (interval === "year") return "- Annual Subscription";
    if (interval === "month") return "- Monthly Subscription";
    return "";
  };

  return (
    <div className="membership container-fluid ">
      <div className="row">
        <div className="col-12 subscription">
          <h5>Current Subscription</h5>
          <div className="subscription-content ">
            <div className="row">
              <div className="col-md-9 col-12 ">
                <div className="expire flex-row align-items-center">
                  Subscription{" "}
                  {subscriptionStatus === "active"
                    ? "renews"
                    : subscriptionStatus === "canceling"
                    ? "expires"
                    : "ended"}{" "}
                  on:&nbsp;
                  <h3
                    style={{
                      alignItems: "center",
                      marginBottom: 0,
                      fontSize: "1.8em",
                    }}
                  >
                    {moment(subscription?.current_period_end * 1000).format(
                      "MMMM DD, YYYY"
                    )}
                  </h3>
                  {subscriptionStatus === "canceling" && (
                    <Link
                      to={`/subscription/renew/${subscription?.id}`}
                      style={{
                        // color: "blue",
                        paddingLeft: 15,
                        fontWeight: "bold",
                      }}
                    >
                      Turn on auto-renew
                    </Link>
                  )}
                  {subscriptionStatus === "expired" && (
                    <Link
                      to={`/subscription/reactivate`}
                      style={{
                        // color: "blue",
                        paddingLeft: 15,
                        fontWeight: "bold",
                      }}
                    >
                      Reactivate subscription
                    </Link>
                  )}
                </div>

                <div className="plan">
                  <i className="d-sm-inline d-none fa fa-money-check"></i>
                  <div className="plan-detail">
                    <h3>{`${PLAN_NAME} ${getPlanName(
                      subscription?.plan?.interval
                    )}`}</h3>
                    <p>
                      {(subscription?.plan?.amount / 100).toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                        }
                      )}
                      /{subscription?.plan?.interval} plus applicable taxes
                    </p>
                  </div>
                </div>
              </div>
              <div className="button-group col-md-2 col-12 text-center d-flex flex-column justify-content-center align-items-center">
                {subscriptionStatus === "active" && (
                  <div>
                    <Link to={`/subscription/change-plan/${subscription?.id}`}>
                      <Button primary>Change&nbsp;Plan</Button>
                    </Link>
                    <div style={{ paddingTop: 5 }}>
                      <Link to={`/subscription/cancel/${subscription?.id}`}>
                        Cancel
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <h5>Billing Information</h5>
          <Elements stripe={stripeTestPromise}>
            <Billing />
          </Elements>
          <div className="col-12 history">
            <h5>Billing History</h5>
            {renderInvoices()}
          </div>
        </div>
      </div>
    </div>
  );
}
